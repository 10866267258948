import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { Button } from "react-daisyui";
import { useState } from "react";

function ResetPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (email === "") {
      return setError("error.1");
    }
    setIsLoading(true);

    const uri = `${process.env.REACT_APP_API_URL}/profile/forgot-password?email=${email}`;

    try {
      const response = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        const json = await response.json();
        setError(`error.${json.message}`);
        return;
      }
      setError('');
      setEmail('')
      navigate('/auth/validate-reset-password');
    } catch (error) {
      setError('error.unexpected');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='flex flex-col w-full sm:w-2/3 px-4'>
      <div className='flex flex-col gap-y-5'>
        <p className='text-3xl font-semibold opacity-90 my-2'>{t('reset-password.title')}</p>
        <form onSubmit={handleSubmit} className='flex flex-col gap-y-1'>
          <div>
            <label className="form-control w-full">
              <input
                type="email"
                placeholder='Email adress'
                className={`input input-bordered w-full focus:input-primary ${error && !email ? 'input-error ring-1 ring-error' : ''}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
          </div>

          {error && (
            <div>
              <div className="flex items-center absolute">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6 text-error mx-1 my-2"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="text-error text-sm font-semibold mx-1 my-2">
                  {t(error)}
                </div>
              </div>
            </div>
          )}

          <div className='pb-6 mt-10'>
            <Button type="submit" loading={isLoading} disabled={isLoading} className="btn btn-primary w-full rounded-xl" >{t('reset-password.send-link')}</Button>
          </div>
          <div >
            <Link to="../" className="btn btn-active btn-link">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
              </svg>
              {t('reset-password.back-to-login')}
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword

// api/profile/forgot-password?email=...... result 200
// api/profile/forgot-password/reset {key:....., newPassword:...}