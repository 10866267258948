import { useTranslation } from 'react-i18next';
import { icons } from "../constants"

function NoContent() {
    const { t } = useTranslation();
    return (
        <div className='flex flex-col items-center justify-center h-[50vh] text-center'>
            <img alt="404" src={icons.notFound} className='w-48' />
            <div className='text-xl font-semibold mt-5'>{t('noContent.title')}</div>
            {/* <div className='opacity-60 mt-2'>{t('noContent.sub-title')}</div> */}
        </div>
    )
}

export default NoContent