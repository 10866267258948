import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../context/GlobalProvider';
import Error from '../components/Error';
import { Button } from 'react-daisyui';
import Alert from '../components/Alert';

function Profile() {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const { token, user, setUser } = useGlobalContext();
  const [fileData, setFileData] = useState(null);

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  })
  const [error, setError] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    if (user) {
      setForm({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone
      });
      setImagePreview(`${process.env.REACT_APP_API_URL}/profile/${user.id}/image?${user.imagePath}`);
    }
  }, [user]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setFileData(file);
    }
  };

  const handleSubmit = async () => {
    if (form.firstName === "" || form.lastName === ""
      || form.email === "" || form.phone === "") {
      return setError("error.1");
    }
    setIsUpdating(true);

    const uri = '/api/profile';
    const { firstName, lastName, email, phone } = form;
    const profile = { id: user.id, firstName, lastName, email, phone };

    const formData = new FormData();
    formData.append('profile', new Blob([JSON.stringify(profile)], { type: 'application/json' }));
    fileData && formData.append('image', fileData);

    try {
      const response = await fetch(uri, {
        method: 'PUT',
        body: formData,
        headers: {
          'authorization': `Bearer ${token}`
        }
      });

      const json = await response.json();
      if (response.status === 500) {
        setError('error.unexpected');
        return;
      }
      if (!response.ok) {
        setError(json.message);
        return;
      }

      setUser(json);
      const tkn = {
        user: json,
        token
      };
      localStorage.setItem('user', JSON.stringify(tkn));
      setError('');
      setAlertMessage(t("commons.profile-change"));
      setAlertType('alert-success');
      setShowAlert(true);
    } catch (error) {
      setError('error.unexpected');
    } finally {
      setIsUpdating(false)
    }
  }
  return (
    <div className='py-24'>
      <div className="pt-8 px-10">
        <div className="text-3xl font-medium">{t("profile.Account")}</div>
      </div>

      <div className='flex flex-col xl:flex-row gap-6 py-10 px-2 lg:px-8'>

        <div className='h-min border text-center rounded-2xl bg-base-100 xl:w-1/3 overflow-hidden'>
          <div className='py-10'>
            <div>
              {imagePreview ? (
                <div className="avatar">
                  <div className="w-28 rounded-full">
                    <img src={imagePreview} alt="profile" />
                  </div>
                </div>
              ) : (
                <div className="p-12 rounded-full border-2 border-dashed flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                  </svg>
                </div>
              )}
            </div>

            <div className='mt-2'>
              <span className='text-3xl font-semibold'>{user.firstName + " " + user.lastName}</span>
              <p className='opacity-50'>{user.email}</p>
            </div>
          </div>
          <div className='border-t p-4 text-lg text-primary font-medium hover:bg-base-200 hover:cursor-pointer' onClick={handleButtonClick}>
            {t("profile.upload image")}
          </div>
          <input type='file' ref={fileInputRef} hidden onChange={handleFileChange} accept="image/png, image/jpeg" />
        </div>

        <div className='border rounded-2xl bg-base-100 xl:w-2/3'>
          <div className='text-xl font-medium p-6 border-b'>
            {t("commons.Profile")}
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-4 py-8 px-4'>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.FirstName")} *</span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full focus:input-primary"
                  value={form.firstName}
                  onChange={(e) => setForm({ ...form, firstName: e.target.value })}
                />
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.LastName")} *</span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full focus:input-primary"
                  value={form.lastName}
                  onChange={(e) => setForm({ ...form, lastName: e.target.value })}
                />
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.Email")} *</span>
                </div>
                <input
                  type="email"
                  className="input input-bordered w-full focus:input-primary"
                  value={form.email}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.Telephone")} *</span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full focus:input-primary"
                  value={form.phone}
                  onChange={(e) => setForm({ ...form, phone: e.target.value })}
                />
              </label>
            </div>

          </div>
          <div className={`md:flex items-center py-4 px-8 ${error ? 'justify-between' : 'justify-end'}`}>
            {error &&
              <Error error={error} />
            }
            <div className='flex items-center gap-x-3 justify-end p-4'>
              <Button loading={isUpdating} disabled={isUpdating} className='btn btn-primary px-8' onClick={handleSubmit}>{t("profile.Save changes")}</Button>
            </div>
          </div>
        </div>
      </div>
      {/* Alert component */}
      <Alert message={alertMessage} type={alertType} showAlert={showAlert} setShowAlert={setShowAlert} />
    </div>
  )
}

export default Profile