import React from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';

const DeliveriesBarchart = ({data}) => {
  return (
    <ResponsiveContainer width="100%" height={330}>
      <BarChart data={data}>
        <CartesianGrid vertical={false} strokeDasharray="3" strokeOpacity={0.5} />
        <XAxis dataKey="name" tick={{ fontSize: 12, fontWeight: 'semibold'}} tickLine={false} axisLine={false} />
        <YAxis tick={{ fontSize: 10, fontWeight: 'semibold' }} tickLine={false} axisLine={false} />
        <Bar dataKey="value" className="fill-primary" radius={[3, 3, 0, 0]} barSize={40} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default DeliveriesBarchart;
