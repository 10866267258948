import React from 'react'

function Loading({heigh}) {
    return (
        <div className={`flex justify-center items-center ${heigh}`}>
            <span className="loading loading-bars w-6"></span>
        </div>
    )
}

export default Loading