import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function CreateCourier() {

  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const {t} = useTranslation();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>

      <div className='flex text-lg items-center px-8 hover:underline font-medium'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 me-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
        <Link to="../">{t("couriers.Couriers")}</Link>
      </div>

      <div className="px-8 pt-4">
        <div className="text-3xl font-medium">{t("couriers.Create courier")}</div>
      </div>

      <div className='p-10'>

        <div className='border rounded-2xl pt-6 bg-base-100'>

          <div className='flex items-center gap-4 px-6'>
            <div>
              {imagePreview ? (
                <div className="avatar">
                  <div className="w-28 rounded-full">
                    <img src={imagePreview} alt='client' />
                  </div>
                </div>
              ) : (
                <div className="p-12 rounded-full border-2 border-dashed flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                  </svg>
                </div>
              )}
            </div>

            <div>
              <span className='text-lg font-semibold'>Avatar</span>
              <p className='text-sm font-medium opacity-50'>Min 400x400px, PNG or JPEG</p>
              <button className='btn btn-outline btn-sm mt-2' onClick={handleButtonClick}>
                {t("commons.Select")}
              </button>
              <input type='file' ref={fileInputRef} hidden onChange={handleFileChange} accept="image/png, image/jpeg" />
            </div>
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4 p-8'>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.FirstName")} *</span>
                </div>
                <input type="text" className="input input-bordered w-full focus:input-primary" />
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.LastName")} *</span>
                </div>
                <input type="text" className="input input-bordered w-full focus:input-primary" />
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.Email")} *</span>
                </div>
                <input type="text" className="input input-bordered w-full focus:input-primary" />
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.Telephone")} *</span>
                </div>
                <input type="text" className="input input-bordered w-full focus:input-primary" />
              </label>
            </div>

          </div>

          <div className='flex items-center gap-3 justify-end p-4'>
            <Link to="../" className='btn btn-ghost'>{t("commons.cancel")}</Link>
            <button className='btn btn-primary px-8'>{t("couriers.Create courier")}</button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default CreateCourier