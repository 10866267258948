import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EmployeStatus from '../../components/EmployeStatus';

function Couriers() {

  const rows = Array.from({ length: 5 });
  const { t } = useTranslation();

  return (
    <div>
      {/* header */}
      <div className="flex justify-between p-8">
        <div>
          <div className="text-4xl font-medium">{t("couriers.Couriers")}</div>
          <div className='flex gap-2 mt-2'>
          <div className="tooltip tooltip-right" data-tip={t("commons.v2tooltip")}>
            <button className='btn btn-ghost btn-sm'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
              {t("commons.import")}
            </button>
            <button className='btn btn-ghost btn-sm'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
              </svg>
              {t("commons.export")}
            </button>
            </div>
          </div>
        </div>
        <div>
          <Link to="create" className='btn btn-primary rounded-2xl'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            {t("commons.Add")}
          </Link>
        </div>
      </div>

      {/* content */}
      <div className='p-6'>
        {/* content header */}
        <div className='p-4 border rounded-2xl mb-4 flex justify-between items-center'>

          <label className="input input-bordered focus-within:input-primary flex items-center gap-2 w-full md:w-2/3 lg:w-1/2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
            <input type="text" className="grow" placeholder="Search" />
          </label>

          <div className='hidden md:flex items-center gap-3'>
            <select className="select select-bordered focus:select-primary">
              <option selected>{t("commons.All")}</option>
              <option>{t("commons.Active")}</option>
              <option>{t("commons.Inactive")}</option>
            </select>

            <select className="select select-bordered focus:select-primary">
              <option selected>{t("commons.Newest")}</option>
              <option>{t("commons.Oldest")}</option>
            </select>
          </div>
        </div>

        {/* data table */}
        <div className="overflow-x-auto border rounded-tl-2xl rounded-tr-2xl">
          <table className="table w-full min-w-[640px] text-center">
            {/* head */}
            <thead className='bg-base-200'>
              <tr >
                <th>#</th>
                <th>{t("commons.Name")}</th>
                <th>{t("commons.Status")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {rows.map((_, index) => (
                // rows
                <tr key={index}>
                  <td>
                    <p className='badge badge-sm badge-ghost font-medium'>DL-001</p>
                  </td>
                  <td>
                    <div className="flex items-center gap-3 justify-center">
                      <div className="avatar">
                        <div className="mask mask-squircle w-12 h-12">
                          <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" alt="Avatar Tailwind CSS Component" />
                        </div>
                      </div>
                      <div>
                        <div className="font-bold">Hart Hagerty</div>
                        <div className="text-sm opacity-50">United States</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <EmployeStatus status="Active" />
                  </td>
                  <th>
                    <Link to={`${index}`} className="btn btn-ghost btn-sm">{t("commons.details")}</Link>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* pagination */}
        <div className="flex justify-end items-center py-2 border-x border-b rounded-bl-2xl rounded-br-2xl">

          <div className='flex gap-2 items-center'>
            <div>
              {t("commons.rows")} :
            </div>
            <select className="select select-sm">
              <option selected>5</option>
              <option>10</option>
              <option>15</option>
            </select>
          </div>

          <div className='flex'>
            <button className="btn btn-sm btn-ghost" >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>
            </button>
            <button className="btn btn-sm" > 1 </button>
            <button className="btn btn-sm btn-ghost" > 2 </button>
            <button className="btn btn-sm btn-ghost" > 3 </button>
            <button className="btn btn-sm btn-ghost" >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Couriers