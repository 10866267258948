import React from 'react'
import { useTranslation } from 'react-i18next'

function EmployeStatus({ status }) {
    const { t } = useTranslation();
    return (
        <div className="badge badge-outline flex gap-1">
            {status === 'ACTIVE' ?
                <div className='bg-success text-white rounded-full p-[2px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-[11px]">
                        <path fillRule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd" />
                    </svg>
                </div>
                :
                <div className='bg-warning text-white rounded-full p-[2px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[11px]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                    </svg>

                </div>
            }
            <div className='font-semibold opacity-60'>
                {t(`commons.${status}`)}
            </div>
        </div>
    )
}

export default EmployeStatus