import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DeliveryStatus from '../../components/DeliveryStatus'
import EmployeStatus from '../../components/EmployeStatus';

function CourierDetails() {
  const rows = Array.from({ length: 5 });
  const { t } = useTranslation();
  return (
    <div>
      <div className='flex text-lg items-center px-8 hover:underline font-medium'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 me-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
        <Link to="../">{t("couriers.Couriers")}</Link>
      </div>
      <div className="flex flex-col gap-y-4 md:flex-row md:justify-between px-8 pb-8 pt-4">

        <div className='flex flex-1 items-center gap-4 text-left'>

          <div className="avatar">
            <div className="w-[75px] rounded-full">
              <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" alt='client' />
            </div>
          </div>

          <div>
            <div className='flex items-baseline gap-4'>
              <span className='text-4xl font-medium'>Ali Ameur</span>

              <EmployeStatus status="Active" />

            </div>
            <div className='opacity-70'>aliam@gmail.com</div>
          </div>
        </div>


        <div className='flex-1 text-right'>
          <Link to="update" className='btn btn-primary rounded-2xl'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
            </svg>
            {t("commons.Update")}
          </Link>
        </div>

      </div>

      <div className='flex flex-col xl:flex-row gap-6 py-10 px-3 2xl:px-8'>

        <div className='flex flex-col xl:w-3/12 auto'>
          <div className='border rounded-2xl pt-6 bg-base-100'>
            <div className='flex items-center gap-4 px-6'>
              <div className='p-2 rounded-full shadow-xl'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
              </div>
              <div className='text-xl font-medium'>
                {t("commons.Contact")}
              </div>
            </div>
            <div className='pt-3 mt-3' >
              <div className='border-b px-4 mt-2'>
                <p className='opacity-75 my-1'>{t("couriers.Courier")} IDS</p>
                <p className='badge badge-ghost font-medium mb-2'>CL-001</p>
              </div>
              <div className='border-b px-4 mt-2'>
                <p className='opacity-75 my-1'>{t("commons.Name")}</p>
                <p className='font-medium my-1'>Ali Ameur</p>
              </div>
              <div className='border-b px-4 mt-2'>
                <p className='opacity-75 my-1'>{t("commons.Email")}</p>
                <p className='font-medium my-1'>aliam@gmail.com</p>
              </div>
              <div className='px-4 mt-2'>
                <p className='opacity-75 my-1'>{t("commons.Telephone")}</p>
                <p className='font-medium mt-1 mb-3'>+0 123 456 789</p>
              </div>
            </div>
          </div>

          <div className='border rounded-2xl p-6 mt-6'>
            <div className='flex items-center gap-4'>
              <div className='p-2 rounded-full shadow-lg'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
                </svg>
              </div>
              <div className='text-xl font-medium'>
                {t("commons.Security")}
              </div>
            </div>
            <div className='mt-7 mb-1'>
              <button className='btn btn-error text-base-100'>{t("couriers.Delete courier")}</button>
              <div className='text-sm opacity-75 mt-4'>
                {t("couriers.delete-warning")}
              </div>
            </div>
          </div>
        </div>

        <div className='flex-col xl:w-9/12'>

          {/* deliveries */}
          <div>
            <div className='flex items-center gap-4 px-6 pb-6'>
              <div className='p-2 rounded-xl shadow-lg'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                </svg>
              </div>
              <div className='text-xl font-medium'>
                {t("delivery-agent.latest deliveries")}
              </div>
            </div>
            {/* data table */}
            <div className="overflow-x-auto border rounded-tl-2xl rounded-tr-2xl">
              <table className="table table-lg w-full min-w-[640px] text-center">
                {/* head */}
                <thead className='bg-base-200'>
                  <tr >
                    <th>#</th>
                    <th>{t("commons.Organisation")}</th>
                    <th>{t("commons.Client")}</th>
                    <th>{t("commons.Depart Hour")}</th>
                    <th>{t("commons.Delivery Hour")}</th>
                    <th>{t("commons.Status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((_, index) => (
                    // rows
                    <tr key={index}>
                      <td>
                        <p className='badge badge-sm badge-ghost font-medium'>LV-001</p>
                      </td>
                      <td>IBM</td>
                      <td>Ali Ameur</td>
                      <td>
                        <span className="badge badge-ghost badge-sm">11:30 pm</span>
                      </td>
                      <td>
                        <span className="badge badge-ghost badge-sm">12:45 pm</span>
                      </td>
                      <td>
                        <DeliveryStatus status="Available" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* pagination */}
            <div className="flex justify-end py-2 px-4 border-x border-b rounded-bl-2xl rounded-br-2xl">
              <Link to="/admin/deliveries" className='btn btn-ghost btn-sm'>
                {t("commons.view all")}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
              </Link>
            </div>
          </div>

          {/* returns */}
          <div className='mt-8'>
            <div className='flex items-center gap-4 px-6 pb-6'>
              <div className='p-2 rounded-xl shadow-lg'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                </svg>
              </div>
              <div className='text-xl font-medium'>
                {t("delivery-agent.latest returns")}
              </div>
            </div>
            {/* data table */}
            <div className="overflow-x-auto border rounded-tl-2xl rounded-tr-2xl">
              <table className="table table-lg w-full min-w-[640px] text-center">
                {/* head */}
                <thead className='bg-base-200'>
                  <tr >
                    <th>#</th>
                    <th>{t("commons.Organisation")}</th>
                    <th>{t("commons.Return Date")}</th>
                    <th>{t("commons.Type")}</th>
                    <th>{t("commons.Status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((_, index) => (
                    // rows
                    <tr key={index}>
                      <td>
                        <p className='badge badge-sm badge-ghost font-medium'>RT-001</p>
                      </td>
                      <td>
                        AliAmeur
                      </td>
                      <td>12 may</td>
                      <td>Livreur</td>
                      <td>
                        <DeliveryStatus status="Available" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* pagination */}
            <div className="flex justify-end py-2 px-4 border-x border-b rounded-bl-2xl rounded-br-2xl">
              <Link to="/admin/returns" className='btn btn-ghost btn-sm'>
                {t("commons.view all")}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
              </Link>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default CourierDetails