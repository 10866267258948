import React from 'react';
import { useTranslation } from 'react-i18next';

function DashboardProgressIndicator({ progress }) {
    const { t } = useTranslation();

    const getSvg = (progress) => {
        if (progress < 0) {
            return (
                <div className="text-error flex gap-1 font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181" />
                    </svg>
                    {progress}%
                </div>
            );
        } else {
            return (
                <div className="text-success flex gap-1 font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
                    </svg>
                    {progress}%
                </div>
            );
        }
    };

    return (
        <div className="flex gap-2 text-sm mt-3">
            {getSvg(progress)}
            <p className="opacity-70">{t("dasboard.since-last-month")}</p>
        </div>
    );
}

export default DashboardProgressIndicator;
