import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useGlobalContext } from '../context/GlobalProvider';
import { useTranslation } from 'react-i18next';

function InfinitScroll({ setValue, label, data, deliveryAgent }) {
  const { token } = useGlobalContext();
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);

  const getName = (value) => {
    return deliveryAgent ? `${value.firstName} ${value.lastName}` : value.name;
  };

  const loadOptions = async (search, loadOptions, { page }) => {
    const response = await fetch(`/api/${data}?search=${search}&page=${page}&size=8${deliveryAgent ? '&appUserType=DELIVERY_PERSON' : ''}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const responseJSON = await response.json();
    const total = response.headers.get('x-total-count');

    return {
      options: responseJSON.map(value => ({ label: getName(value), value: value.id })),
      hasMore: (page + 1) * 8 < total,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <label className="form-control">
      <div className="label">
        <span className="label-text font-semibold">{t(`commons.${label}`)}</span>
      </div>
      <AsyncPaginate
        value={selected}
        loadOptions={loadOptions}
        onChange={(selectedOption) => {
          setValue(selectedOption.value);
          setSelected(selectedOption);
        }}
        additional={{ page: 0 }}
      />
    </label>
  );
}

export default InfinitScroll;
