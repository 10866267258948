import { Link } from 'react-router-dom';
import DeliveriesBarchart from '../components/DeliveriesBarchart';
import DeliveredPieChart from '../components/DeliveredPieChart';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import useFetchData from '../hooks/FetchData';
import DashboardProgressIndicator from '../components/DashboardProgressIndicator';
import DeliveryStatus from '../components/DeliveryStatus';
import { format, parseISO } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

function Dashboard() {

    const { t, i18n } = useTranslation();

    const fetchStatistics = (token) => {
        return fetch(`/api/dashboard/statistics`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        });
    };

    const fetchClients = (token) => {
        return fetch(`/api/clients?page=$0&size=5`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        });
    };

    const fetchDeliveries = (token) => {
        return fetch(`/api/deliveries?page=0&size=5`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        });
    };

    const { data, isLoading } = useFetchData(fetchStatistics, []);
    const { data: clientsData } = useFetchData(fetchClients, []);
    const { data: deliveriesData } = useFetchData(fetchDeliveries, []);

    const getLocale = () => {
        switch (i18n.language) {
            case 'fr':
                return fr;
            case 'en':
            default:
                return enUS;
        }
    };

    return (
        <div className="py-24 2xl:py-28 flex flex-col gap-14 px-3 lg:px-6">

            {isLoading || !data
                ?
                <Loading heigh="h-[75vh]" />
                :
                <div className='flex flex-col gap-14'>
                    {/*   statistics   */}
                    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-4">

                        <div className="flex flex-col gap-4 border hover:border-0 hover:shadow-lg rounded-2xl p-6">
                            <div className='flex justify-between '>
                                <div className="flex justify-center flex-col gap-3">
                                    <p className="opacity-80">{t("dasboard.CLIENTS")}</p>
                                    <p className="text-3xl font-semibold">{data.nbrClients}</p>
                                </div>
                                <div>
                                    <div className='p-2 rounded-full shadow-xl'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <DashboardProgressIndicator progress={data.clientProgress} />
                        </div>

                        <div className="flex flex-col gap-4 border hover:border-0 hover:shadow-lg rounded-2xl p-6">
                            <div className='flex justify-between'>
                                <div className="flex justify-center flex-col gap-3">
                                    <p className="opacity-80">{t("dasboard.ORGANISATIONS")}</p>
                                    <p className="text-3xl font-semibold">{data.nbrOrganizations}</p>
                                </div>
                                <div>
                                    <div className='p-2 rounded-full shadow-xl'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <DashboardProgressIndicator progress={data.organizationProgress} />
                        </div>

                        <div className="flex flex-col justify-between border hover:border-0 hover:shadow-lg rounded-2xl p-6">
                            <div className="flex justify-between">
                                <div className="flex flex-col gap-3">
                                    <p className="opacity-80">{t("dasboard.DELIVERIES")}</p>
                                    <p className="text-3xl font-semibold">{data.nbrDeliveries}</p>
                                </div>
                                <div>
                                    <div className='p-2 rounded-full shadow-xl'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <progress className="progress progress-primary w-56" value={data.percentageDelivered} max="100"></progress>
                            </div>
                        </div>

                        <div className="flex flex-col justify-between border hover:border-0 hover:shadow-lg rounded-2xl p-6">
                            <div className="flex justify-between">
                                <div className="flex flex-col gap-3">
                                    <p className="opacity-80">{t("dasboard.RETURNS")}</p>
                                    <p className="text-3xl font-semibold">{data.nbrCoolerReturns}</p>
                                </div>
                                <div>
                                    <div className='p-2 rounded-full shadow-xl'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mt-3">
                                <progress className="progress progress-primary w-56" value="20,7" max="100"></progress>
                            </div> */}
                        </div>
                    </div>

                    {/* analytics */}
                    <div className="flex flex-col xl:flex-row gap-4">
                        <div className='border rounded-2xl w-full xl:w-2/3 p-3'>
                            <div className='text-lg font-semibold p-4'>
                                {t("dasboard.Deliveries")}
                            </div>
                            <div className='mt-6'>
                                <DeliveriesBarchart data={data.deliveriesByMonth} />
                            </div>
                        </div>
                        <div className='border rounded-2xl w-full xl:w-1/3 p-3'>
                            <div className='text-lg font-semibold p-4'>
                                {t("dasboard.Deliveried by")}
                            </div>
                            <div>
                                <DeliveredPieChart data={data.deliveriesByState} />
                                <div className='flex gap-2 justify-center pb-4 font-medium'>
                                    <div className='flex items-center gap-2 text-primary'>
                                        <div className='w-4 h-4 rounded-full bg-primary'></div>
                                        {t("dasboard.Delivery")}
                                    </div>
                                    <div className='flex items-center gap-2 text-success'>
                                        <div className='w-4 h-4 rounded-full bg-success'></div>
                                        {t("commons.Courtier")}
                                    </div>
                                    <div className='flex items-center gap-2 text-warning'>
                                        <div className='w-4 h-4 rounded-full bg-warning'></div>
                                        {t("dasboard.Pending")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {/* latest */}
            <div className="flex flex-col xl:flex-row gap-4">

                {
                    (clientsData && Object.keys(clientsData).length !== 0) &&
                    /* {clients */
                    <div className="w-full xl:w-1/3">
                        <div className='text-lg font-semibold p-4 border-x border-t rounded-tl-2xl rounded-tr-2xl'>
                            {t("dasboard.newest clients")}
                        </div>
                        {/* data table */}
                        <div className="overflow-x-auto border">
                            <table className="table w-full">
                                {/* head */}
                                <thead>
                                    <tr >
                                        <th>{t("commons.Name")}</th>
                                        <th>{t("commons.City")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientsData.map((client, index) => (
                                        // rows
                                        <tr key={index}>
                                            <td>
                                                <div className="flex items-center gap-3">
                                                    <div className="avatar">
                                                        <div className="mask mask-squircle w-12 h-12">
                                                            <img src={`${process.env.REACT_APP_API_URL}/clients/${client.id}/image?${client.imagePath}`} alt="client" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="font-bold">{client.name}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='font-medium'>{client.city}</div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* footer */}
                        <div className="flex justify-end py-2 px-4 border-x border-b rounded-bl-2xl rounded-br-2xl">
                            <Link to="/admin/clients" className='btn btn-ghost btn-sm'>
                                {t("commons.view all")}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                }

                {
                    (deliveriesData && Object.keys(deliveriesData).length !== 0) &&
                    /* deliveries */
                    <div className="w-full xl:w-2/3">
                        <div className='text-lg font-semibold p-4 border-x border-t rounded-tl-2xl rounded-tr-2xl'>
                            {t("dasboard.latest deliveries")}
                        </div>
                        {/* data table */}
                        <div className="overflow-x-auto border">
                            <table className="table table-lg w-full min-w-[640px] text-center">
                                <thead>
                                    <tr >
                                        <th>{t("commons.Organisation")}</th>
                                        <th>{t("commons.Depart Hour")}</th>
                                        <th>{t("delivery.Arrive Hour")}</th>
                                        <th>{t("commons.Status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deliveriesData.map((delivery, index) => (
                                        // rows
                                        <tr key={index}>
                                            <td>{delivery.organizationName}</td>
                                            <td>
                                                {delivery.startDate && <span className="badge badge-ghost badge-sm">{delivery.startDate ? format(parseISO(delivery.startDate), 'PPp', { locale: getLocale() }) : ''}</span>}
                                            </td>
                                            <td>
                                                {delivery.endDate && <span className="badge badge-ghost badge-sm">{delivery.endDate ? format(parseISO(delivery.endDate), 'PPp', { locale: getLocale() }) : ''}</span>}
                                            </td>
                                            <td>
                                                <DeliveryStatus
                                                    status={delivery.status}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* footer */}
                        <div className="flex justify-end py-2 px-4 border-x border-b rounded-bl-2xl rounded-br-2xl">
                            <Link to="/admin/deliveries" className='btn btn-ghost btn-sm'>
                                {t("commons.view all")}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Dashboard