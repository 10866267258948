import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fixing the default icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

// Custom markers with different colors
const blueIcon = new L.Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const redIcon = new L.Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// Auto open popup component
function OpenPopup({ markerRef }) {
  const map = useMap();
  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup();
    }
  }, [map, markerRef]);

  return null;
}

function DeliveryLocation({ long, lat, long2, lat2 }) {
  const position = [lat, long];
  const position2 = [lat2, long2];
  const markerRef1 = React.useRef();
  const markerRef2 = React.useRef();

  return (
    <MapContainer center={position} zoom={13} style={{ height: '600px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={blueIcon} ref={markerRef1}>
        <Popup>
          <div style={{ color: 'blue', fontWeight: 'bold' }}>Localisation</div>
        </Popup>
        <OpenPopup markerRef={markerRef1} />
      </Marker>
      {long2 && lat2 && (
        <Marker position={position2} icon={redIcon} ref={markerRef2}>
          <Popup>
            <div style={{ color: 'red', fontWeight: 'bold' }}>Organization</div>
          </Popup>
          <OpenPopup markerRef={markerRef2} />
        </Marker>
      )}
    </MapContainer>
  );
}

export default DeliveryLocation;
