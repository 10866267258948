import { useEffect, useState } from 'react'
import { useGlobalContext } from '../context/GlobalProvider';

function useFetchData(fn, deps = []) {

    const { user, token } = useGlobalContext();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await fn(token)
            setTotal(response.headers.get('x-total-count'))
            const json = await response.json()
            if (response.ok) {
                setData(json)
            }
            if (!response.ok) {
                setError(json.error)
            }
        } catch (error) {
            setError('unexpected');
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(user){
        fetchData()
        }
    }, [...deps])

    return { data, isLoading, error, total };
}

export default useFetchData