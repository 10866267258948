import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { logos } from "../constants"
const sidebarItems = [
    {
        id: 1,
        label: 'sideBar.Dashboard',
        icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
        link: '/admin'
    },
    {
        id: 2,
        label: 'sideBar.Deliveries',
        icon: "M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75",
        link: 'deliveries'
    },
    {
        id: 3,
        label: 'sideBar.Returns',
        icon: "M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z",
        link: 'returns'
    },
    {
        id: 4,
        label: 'sideBar.Punch In/Out',
        icon: "M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5",
        link: 'punch'
    },
    {
        id: 5,
        label: 'sideBar.Employees',
        icon: "M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z",
        submenu: [
            { id: '5-1', label: 'sideBar.Delivery Agents', link: 'delivery-agents' },
            // { id: '5-2', label: 'sideBar.Couriers', link: 'couriers' }
        ]
    },
    {
        id: 6,
        label: 'sideBar.Clients',
        icon: "M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z",
        link: 'clients'
    },
    {
        id: 7,
        label: 'sideBar.Organisations',
        icon: "m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z",
        link: 'organisations'
    }
];

function AdminSideBar() {

    const { t } = useTranslation();

    return (
        <div className="drawer-side z-50" >
            <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label>
            <ul className="menu text-[15px] p-4 w-[300px] min-h-full bg-base-300 text-base-content font-medium" data-theme="dark">

                {/* Sidebar content here */}

                <NavLink to="/admin" className="flex items-center gap-x-4 text-lg mb-3 font-semibold">
                    <img alt="Ids" src={logos.logo} className="w-12" />
                    IDS livraison express
                </NavLink>

                {sidebarItems.map((item) => (
                    <li key={item.id}>
                        {!item.submenu ? (
                            <NavLink to={item.link} end={item.link === '/admin'}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={item.icon} /></svg>
                                {t(item.label)}
                            </NavLink>) : (
                            <details close>
                                <summary>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={item.icon} /></svg>
                                    {t(item.label)}
                                </summary>
                                <ul>
                                    {item.submenu.map((subItem) => (<li key={subItem.id}>
                                        <NavLink to={subItem.link}>{t(subItem.label)}</NavLink>
                                    </li>))}
                                    <li className="ms-4 text-gray-500">{t('sideBar.Couriers')}</li>
                                </ul>
                            </details>
                        )}

                    </li>
                ))}

            </ul>

        </div>
    )
}

export default AdminSideBar;
