import React from 'react';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';

const DeliveredPieChart = ({data}) => {
  const getColorClass = (name) => {
    switch (name) {
      case 'pending':
        return 'fill-warning';
      case 'deliveredByDeliveryPerson':
        return 'fill-primary';
      case 'deliveredByBroker':
        return 'fill-success';
      default:
        return 'fill-primary';
    }
  };

  return (
    <ResponsiveContainer width="100%" height={330}>
      <PieChart>
        <Pie dataKey="value" data={data} cx="50%" cy="50%" innerRadius={80} outerRadius={120} stroke="none">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} className={getColorClass(entry.name)} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default DeliveredPieChart;
