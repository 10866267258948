import { Link, useParams, useNavigate } from 'react-router-dom';
import useFetchData from '../../hooks/FetchData';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { useState } from 'react';
import { Button } from 'react-daisyui';
import { useGlobalContext } from '../../context/GlobalProvider';
import Error from '../../components/Error';

function ClientDetails() {
  const { t } = useTranslation()
  const { id } = useParams()
  const { token } = useGlobalContext()

  const fetchClient = (token) => {
    return fetch(`/api/clients/${id}`, {
      headers: {
        'authorization': `Bearer ${token}`
      }
    });
  };
  const { data, isLoading } = useFetchData(fetchClient);

  const [error, setError] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const navigate = useNavigate();

  const deleteClient = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch(`/api/clients/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 204) {
        setError('');
        navigate('../', { replace: true });
      } else {
        const json = await response.json();
        if (response.status === 500) {
          setError('error.unexpected');
          return;
        }
        if (!response.ok) {
          setError(json.error);
          return;
        }
      }
    } catch (error) {
      setError('error.unexpected');
    } finally {
      setIsDeleting(false);
    }
  };


  return (
    <div>
      <div className='flex text-lg items-center px-2 md:px-6 w-min hover:underline font-medium'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 me-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
        <Link to="../">{t("client.Clients")}</Link>
      </div>

      {isLoading
        ?
        <Loading heigh="h-[75vh]" />
        :
        <>
          <div className="flex flex-col gap-y-4 md:flex-row md:justify-between px-4 md:px-6 pb-8 pt-4">

            <div className='flex flex-1 items-center gap-4 text-left'>

              <div className="avatar">
                <div className="w-[75px] rounded-full">
                  <img src={`${process.env.REACT_APP_API_URL}/clients/${data.id}/image?${data.imagePath}`} alt='client' />
                </div>
              </div>

              <div>
                <span className='text-4xl font-medium'>{data.name}</span>
                <div className='opacity-70'>{data.email}</div>
              </div>
            </div>

            <div className='flex-1 text-right'>
              <Link to="update" className='btn btn-primary rounded-2xl'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                </svg>
                {t("commons.Update")}
              </Link>
            </div>

          </div>

          <div className='flex flex-col xl:flex-row gap-6 py-10 px-2 md:px-6'>

            <div className='flex flex-col xl:w-1/3'>
              <div className='border rounded-2xl pt-6 bg-base-100'>
                <div className='flex items-center gap-4 px-6'>
                  <div className='p-2 rounded-full shadow-xl'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                    </svg>
                  </div>
                  <div className='text-xl font-medium'>
                    {t("commons.Contact")}
                  </div>
                </div>
                <div className='pt-3 mt-3' >
                  <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("commons.Client")} IDS</p>
                    <p className='badge badge-ghost font-medium mb-2'>{data.idsRef}</p>
                  </div>
                  <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("commons.Name")}</p>
                    <p className='font-medium my-1'>{data.name}</p>
                  </div>
                  <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("commons.Telephone")} 1</p>
                    <p className='font-medium my-1'>{data.primaryPhone}</p>
                  </div>
                  {data.secondaryPhone && <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("commons.Telephone")} 2</p>
                    <p className='font-medium my-1'>{data.secondaryPhone}</p>
                  </div>}
                  <div className='px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("commons.Email")}</p>
                    <p className='font-medium my-1 mb-3'>{data.email}</p>
                  </div>
                </div>
              </div>

              <div className='border rounded-2xl p-6 mt-6'>
                <div className='flex items-center gap-4'>
                  <div className='p-2 rounded-full shadow-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
                    </svg>
                  </div>
                  <div className='text-xl font-medium'>
                    {t("commons.Security")}
                  </div>
                </div>
                <div className='mt-7 mb-1'>
                  <Button loading={isDeleting} disabled={isDeleting} className='btn btn-error text-base-100' onClick={() => document.getElementById('modal').showModal()}>{t("client.Delete client")}</Button>
                  <div className='text-sm opacity-75 mt-4'>
                    {t("client.delete-client-alert")}
                  </div>
                  {error &&
                    <Error error={error} absolute={true} />
                  }
                </div>
              </div>
            </div>

            <div className='border h-min rounded-2xl xl:w-2/3 pt-6'>
              <div className='flex items-center gap-4 px-6'>
                <div className='p-2 rounded-full shadow-lg'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                  </svg>
                </div>
                <div className='text-xl font-medium'>
                  {t("commons.Address")}
                </div>
              </div>
              <div className='pt-3 mt-3'>
                <div className='border-b px-8 mt-2'>
                  <p className='opacity-75 my-1'>{t("commons.Address")} 1</p>
                  <p className='font-medium my-1'>{data.primaryAddress}</p>
                </div>
                {data.secondaryAddress && <div className='border-b px-8 mt-2'>
                  <p className='opacity-75 my-1'>{t("commons.Address")} 2</p>
                  <p className='font-medium my-1'>{data.secondaryAddress}</p>
                </div>}
                {data.apartment && <div className='border-b px-8 mt-2'>
                  <p className='opacity-75 my-1'>{t("commons.Appartement")}</p>
                  <p className='font-medium my-1'>{data.apartment}</p>
                </div>}
                {data.entryCode && <div className='border-b px-8 mt-2'>
                  <p className='opacity-75 my-1'>{t("commons.Enter Code")}</p>
                  <p className='font-medium my-1'>{data.entryCode}</p>
                </div>}
                {data.postalCode && <div className='border-b px-8 mt-2'>
                  <p className='opacity-75 my-1'>{t("commons.Postal Code")}</p>
                  <p className='font-medium mt-1'>{data.postalCode}</p>
                </div>}
                <div className='px-8 mt-2'>
                  <p className='opacity-75 my-1'>{t("commons.City")}</p>
                  <p className='font-medium my-1 mb-3'>{data.city}</p>
                </div>
              </div>

            </div>

          </div>
        </>
      }

      <dialog id="modal" className="modal">
        <div className="modal-box flex flex-col gap-y-7 px-6 pt-4pb-2 h-max rounded-2xl border-4 border-error">
          <div className='flex items-center gap-4'>
            <div className='p-2 rounded-full shadow-lg'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
              </svg>
            </div>
            <div className="text-xl font-medium">
              {t("commons.Security")}
            </div>
          </div>
          <div className='font-medium text-[18px]'>{t("commons.delete-confirmation")}</div>
          <form method="dialog" className='flex justify-end items-center gap-x-2'>
            <button className="btn btn-error" onClick={deleteClient}>{t("commons.delete")}</button>
            <button className="btn btn-ghost">{t("commons.cancel")}</button>
          </form>
        </div>
      </dialog>

    </div>
  )
}

export default ClientDetails