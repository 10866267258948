import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFetchData from '../../hooks/FetchData';
import Loading from '../../components/Loading'
import NoContent from '../../components/NoContent'
import IdsPagination from '../../components/Pagination';
import EmployeStatus from '../../components/EmployeStatus';
import { useGlobalContext } from '../../context/GlobalProvider';
import { Button } from 'react-daisyui'
import Alert from '../../components/Alert';

function DeliveryAgents() {

    const { t } = useTranslation();
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(5)
    const [search, setSearch] = useState('')
    const [sortOrder, setSortOrder] = useState('firstName,asc');
    const [type, setType] = useState('');
    const [isExporting, setIsExporting] = useState(false)
    const { token } = useGlobalContext()
    const [error, setError] = useState()
    const [showAlert, setShowAlert] = useState(false);

    const fetchDeliveryPersons = (token) => {
        return fetch(`/api/app-users?appUserType=DELIVERY_PERSON&status=${type}&page=${page}&size=${size}&search=${search}&sort=${sortOrder}`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        });
    };

    const { data, isLoading, total } = useFetchData(fetchDeliveryPersons, [page, size, sortOrder, search, type]);

    const handleExport = async () => {
        setIsExporting(true)
        try {
            const response = await fetch(`/api/app-users/export`, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            });
            if (response.status === 500) {
                setError(t('error.unexpected'));
                setShowAlert(true)
                return;
            }
            const blob = await response.blob(); // Get the response as a blob
            const url = window.URL.createObjectURL(blob); // Create a URL for the blob
            const a = document.createElement('a'); // Create an <a> element
            a.href = url;
            const today = new Date();
            const formattedDate = new Intl.DateTimeFormat('en-GB').format(today);
            a.download = 'Livreurs_' + formattedDate;
            document.body.appendChild(a);
            a.click(); // Simulate a click to trigger the download
            document.body.removeChild(a); // Clean up
            window.URL.revokeObjectURL(url); // Release the URL object
        } catch {
            setError('error.unexpected');
        } finally {
            setIsExporting(false)
        }
    }

    return (
        <div>
            {/* header */}
            <div className="flex justify-between py-8 px-4">
                <div>
                    <div className="text-4xl font-medium">{t("delivery-agent.Delivery Agents")}</div>
                    <div className='flex gap-2 mt-2'>
                        <div className="tooltip tooltip-right" data-tip={t("commons.v2tooltip")}>
                            <button className='btn btn-ghost btn-sm'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>
                                {t("commons.import")}
                            </button>
                        </div>
                        <Button className='btn btn-ghost btn-sm' disabled={isExporting} loading={isExporting} onClick={handleExport}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                            </svg>
                            {t("commons.export")}
                        </Button>
                    </div>
                </div>
                <div>
                    <Link to="create" className='btn btn-primary rounded-2xl'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        {t("commons.Add")}
                    </Link>
                </div>
            </div>

            {/* content */}
            <div className='py-10 px-2 lg:px-8'>
                {/* content header */}
                <div className='p-4 border rounded-2xl mb-4 flex justify-between items-center'>

                    <label className="input input-bordered focus-within:input-primary flex items-center gap-2 w-full md:w-2/3 lg:w-1/2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        <input type="text" className="grow" placeholder="phone, name & idsRef" value={search} onChange={(e) => { setSearch(e.target.value); setPage(0); }} />
                    </label>

                    <div
                        className='hidden md:flex items-center gap-3'
                    >
                        <select
                            className="select select-bordered focus:select-primary"
                            value={type}
                            onChange={(e) => { setType(e.target.value); setPage(0) }}
                        >
                            <option value="">{t("commons.All")}</option>
                            <option value="ACTIVE">{t("commons.ACTIVE")}</option>
                            <option value="INACTIVE">{t("commons.INACTIVE")}</option>
                        </select>

                        <select
                            className="select select-bordered focus:select-primary"
                            value={sortOrder}
                            onChange={(e) => { setSortOrder(e.target.value); setPage(0); }}
                        >
                            <option value="firstName,asc">{t("commons.Alphabetical Order")}</option>
                            <option value="created,desc">{t("commons.Newest")}</option>
                            <option value="created,asc">{t("commons.Oldest")}</option>
                        </select>
                    </div>
                </div>

                {isLoading ?
                    <Loading heigh="h-[45vh]" />
                    : (data && Object.keys(data).length !== 0)
                        ?
                        <>
                            {/* data table */}
                            <div className="overflow-x-auto border rounded-tl-2xl rounded-tr-2xl">
                                <table className="table w-full min-w-[640px]">
                                    {/* head */}
                                    <thead className='bg-base-200'>
                                        <tr >
                                            <th>#</th>
                                            <th>{t("commons.Name")}</th>
                                            <th>{t("commons.Status")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((data, index) => (
                                            // rows
                                            <tr key={index}>
                                                <td>
                                                    <p className='badge badge-sm badge-ghost font-medium'>{data.login}</p>
                                                </td>
                                                <td>
                                                    <div className="flex gap-3 items-center">
                                                        <div className="avatar">
                                                            <div className="mask mask-squircle w-12 h-12">
                                                                <img src={`${process.env.REACT_APP_API_URL}/app-users/${data.id}/image?${data.imagePath}`} alt="user" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="font-bold">{data.firstName + " " + data.lastName}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <EmployeStatus status={data.status} />
                                                </td>
                                                <th>
                                                    <Link to={`${data.id}`} className="btn btn-ghost btn-sm">{t("commons.details")}</Link>
                                                </th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* pagination */}
                            <IdsPagination
                                total={total}
                                page={page}
                                size={size}
                                setPage={setPage}
                                setSize={setSize}
                            />
                        </>
                        :
                        <NoContent />
                }
            </div>
            {/* Alert component */}
            <Alert message={error} type="alert-error" showAlert={showAlert} setShowAlert={setShowAlert} />
        </div>
    )
}

export default DeliveryAgents