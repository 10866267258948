import { useTranslation } from 'react-i18next'
import { Pagination, Button } from 'react-daisyui'

function IdsPagination({ total, page, size, setPage, setSize }) {
    const { t } = useTranslation()

    const totalPages = Math.ceil(total / size);

    const handleSizeChange = (e) => {
        setSize(Number(e.target.value));
        setPage(0);
    };

    const renderPages = () => {
        const pages = [];

        if (totalPages <= 5) {
            // Show all pages if total pages <= 5
            for (let i = 0; i < totalPages; i++) {
                pages.push(
                    <Button
                        key={i}
                        className={`btn btn-sm ${i === page ? 'btn-primary' : 'btn-ghost'}`}
                        onClick={() => setPage(i)}
                    >
                        {i + 1}
                    </Button>
                );
            }
        } else {
            // Show first, last, and middle pages with dots
            pages.push(
                <Button
                    key={0}
                    className={`btn btn-sm ${0 === page ? 'btn-primary' : 'btn-ghost'}`}
                    onClick={() => setPage(0)}
                >
                    1
                </Button>
            );

            if (page > 2) {
                pages.push(<span key="dots-start" className="btn btn-sm btn-ghost">...</span>);
            }

            for (let i = Math.max(1, page - 1); i <= Math.min(totalPages - 2, page + 1); i++) {
                pages.push(
                    <Button
                        key={i}
                        className={`btn btn-sm ${i === page ? 'btn-primary' : 'btn-ghost'}`}
                        onClick={() => setPage(i)}
                    >
                        {i + 1}
                    </Button>
                );
            }

            if (page < totalPages - 3) {
                pages.push(<span key="dots-end" className="btn btn-sm btn-ghost">...</span>);
            }

            pages.push(
                <Button
                    key={totalPages - 1}
                    className={`btn btn-sm ${totalPages - 1 === page ? 'btn-primary' : 'btn-ghost'}`}
                    onClick={() => setPage(totalPages - 1)}
                >
                    {totalPages}
                </Button>
            );
        }

        return pages;
    };

    return (
        <div className="border-x border-b rounded-bl-2xl rounded-br-2xl px-4 min-h-12">
            {(totalPages > 1) &&
                <div className='flex flex-col items-end sm:flex-row justify-end sm:items-center gap-5 py-3 '>
                    <div className='flex gap-2 items-center'>
                        <div>
                            {t("commons.rows")} :
                        </div>
                        <select
                            className="select select-sm"
                            value={size}
                            onChange={handleSizeChange}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                        </select>
                    </div>

                    <Pagination >
                        <div className='flex flex-row gap-x-1'>
                            <Button
                                className="btn btn-sm btn-ghost"
                                disabled={page === 0}
                                onClick={() => setPage(page - 1)}
                            >
                                {'<'}
                            </Button>

                            {renderPages()}

                            <Button
                                className="btn btn-sm btn-ghost"
                                disabled={page === totalPages - 1}
                                onClick={() => setPage(page + 1)}
                            >
                                {'>'}
                            </Button>
                        </div>
                    </Pagination>
                </div>}
        </div>
    )
}

export default IdsPagination