import React, { useState } from 'react';
import DeliveryLocation from '../components/DeliveryLocation';
import { useTranslation } from 'react-i18next';
import Tab from '../components/Tab';
import useFetchData from '../hooks/FetchData';
import Loading from '../components/Loading'
import NoContent from '../components/NoContent'
import IdsPagination from '../components/Pagination';
import InfinitScroll from '../components/InfinitScroll';
import { format, parseISO } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

function Punch() {
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(5)
    const [after, setAfter] = useState('')
    const [before, setBefore] = useState('')
    const [deliveryPerson, setDeliveryPerson] = useState(null)
    const [type, setType] = useState("IN")

    const fetchPunches = (token) => {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
            sort: `time,asc`,
        });

        if (deliveryPerson) {
            params.append('doneBy', deliveryPerson);
        }

        if (after) {
            params.append('startsAfter', after);
        }

        if (before) {
            params.append('startsBefore', before);
        }

        return fetch(`/api/punches?${params.toString()}&type=${type}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
    };

    const { data, isLoading, total } = useFetchData(fetchPunches, [page, size, before, after, deliveryPerson, type]);

    const getLocale = () => {
        switch (i18n.language) {
            case 'fr':
                return fr;
            case 'en':
            default:
                return enUS;
        }
    };

    return (
        <div className='py-24'>
            {/* header */}
            <div className="py-8 px-4 lg:px-8">
                <div className="text-3xl font-medium">{t("punch.Punch In/Out")}</div>
            </div>

            <div className='py-6 px-4 lg:px-6'>

                {/* content */}
                <div className='flex flex-col xl:flex-row gap-5'>

                    <div className='xl:w-3/12'>
                        <div className='border rounded-2xl pt-6 bg-base-100'>
                            <div className='flex items-center gap-4 px-6'>
                                <div className='p-2 rounded-full shadow-xl'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                                    </svg>
                                </div>
                                <div className='text-lg font-medium'>
                                    {t("commons.Filters")}
                                </div>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-4 py-6 px-4'>

                                <InfinitScroll
                                    setValue={setDeliveryPerson}
                                    label="Delivery Agent"
                                    data="app-users"
                                    deliveryAgent
                                />

                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-semibold">{t("commons.After")}</span>
                                    </div>
                                    <input
                                        type="date"
                                        value={after}
                                        onChange={(e) => { setAfter(e.target.value); setPage(0); }}
                                        className="input input-sm input-bordered focus:input-primary"
                                    />
                                </label>

                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-semibold">{t("commons.Before")}</span>
                                    </div>
                                    <input
                                        type="date"
                                        value={before}
                                        onChange={(e) => { setBefore(e.target.value); setPage(0); }}
                                        className="input input-sm input-bordered focus:input-primary"
                                    />
                                </label>

                            </div>
                        </div>
                    </div>

                    <div className='xl:w-9/12'>

                        <div className='flex items-center gap-x-3 pb-4 px-4'>
                            <Tab title="IN" value={type} handleClick={() => { setType('IN'); setPage(0); }} />
                            <Tab title="OUT" value={type} handleClick={() => { setType('OUT'); setPage(0); }} />
                        </div>

                        {isLoading ?
                            <div className='flex flex-grow items-center justify-center'>
                                <Loading heigh="h-[45vh]" />
                            </div>
                            : (data && Object.keys(data).length !== 0)
                                ?
                                <>
                                    <div>
                                        {/* data table */}
                                        <div className="overflow-x-auto border rounded-tl-2xl rounded-tr-2xl">
                                            <table className="table text-center table-lg w-full min-w-[640px]">
                                                {/* head */}
                                                <thead className='bg-base-200'>
                                                    <tr >
                                                        <th>#</th>
                                                        <th>{t("commons.Delivery Agent")}</th>
                                                        <th>{t("punch.Hour")}</th>
                                                        {type === 'OUT' && <th>{t("commons.timeCount")}</th>}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((item, index) => (
                                                        // rows
                                                        <tr key={index}>
                                                            <td>{item.idsRef}</td>
                                                            <td>{item.deliveryPersonName}</td>
                                                            <td>
                                                                <span className="badge badge-ghost badge-sm">
                                                                    {item.time ? format(parseISO(item.time), 'PPp', { locale: getLocale() }) : ''}
                                                                </span>
                                                            </td>
                                                            {type === 'OUT' && (
                                                                <td>
                                                                    <span className="badge badge-ghost badge-sm">{item.timeCount}</span>
                                                                </td>
                                                            )}
                                                            <th>
                                                                <button
                                                                    className="btn btn-ghost btn-sm"
                                                                    onClick={() => document.getElementById(`location-modal-${index}`).showModal()}
                                                                >
                                                                    <span className='text-sm'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                                {/* location modal */}
                                                                <dialog id={`location-modal-${index}`} className="modal modal-bottom sm:modal-middle">
                                                                    <div className="modal-box relative p-0">
                                                                        <DeliveryLocation long={item.longitude} lat={item.latitude} />
                                                                    </div>
                                                                    <form method="dialog" className="modal-backdrop">
                                                                        <button>close</button>
                                                                    </form>
                                                                </dialog>
                                                            </th>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* pagination */}
                                        <IdsPagination
                                            total={total}
                                            page={page}
                                            size={size}
                                            setPage={setPage}
                                            setSize={setSize}
                                        />
                                    </div>
                                </>
                                :
                                <div className='flex flex-grow items-center justify-center'>
                                    <NoContent />
                                </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Punch