import React, { useEffect } from 'react';

function Alert({ message, type, showAlert, setShowAlert }) {
    useEffect(() => {
        if (showAlert) {
            const timeout = setTimeout(() => setShowAlert(false), 5000);
            return () => clearTimeout(timeout);
        }
    }, [showAlert, setShowAlert]);

    return (
        <div>
            {showAlert && (
                <div
                    role="alert"
                    className={`alert ${type} md:w-1/2 lg:w-1/3 fixed bottom-0 end-0 m-4`}
                    style={{ zIndex: 1050 }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 shrink-0 stroke-current"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <span>{message}</span>
                </div>
            )}
        </div>
    );
}

export default Alert;
