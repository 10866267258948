import { Outlet} from "react-router-dom";


function AppLayout() {
  return (
    <>
    <Outlet />
    </>
  )
}

export default AppLayout