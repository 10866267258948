import { Outlet } from "react-router-dom";
import Navbar from "../components/NavBar";
import AdminSideBar from "../components/AdminSideBar";

function AdminLayout() {
    return (
        <>
            <div className="drawer lg:drawer-open">
                <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content flex flex-col">
                    {/* Page content here */}
                    <div className='w-full'>

                        {/*   navbar   */}
                        <Navbar />

                        {/*   content   */}
                        <Outlet />

                    </div>
                </div>

                {/*   siddbar   */}
                <AdminSideBar />

            </div>
        </>
    )
}

export default AdminLayout;