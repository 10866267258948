import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import OTPInput from "../components/OTPInput";
import { useState } from "react";
import { Button } from "react-daisyui";

function ValidateResetPassword() {
    const { t } = useTranslation();
    const [form, setForm] = useState({
        key: '',
        newPassword: '',
        newPasswordConf: ''
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleOtpChange = (otp) => {
        setForm({ ...form, key: otp });
    };

    const handleSubmit = async () => {
        if (form.key === "" || form.newPassword === ""
            || form.newPasswordConf === "" || form.key.length !== 5) {
            return setError("error.1");
        }
        if (form.newPassword !== form.newPasswordConf) {
            return setError("error.2");
        }
        setIsLoading(true);

        const { key, newPassword } = form;
        const user = { key, newPassword };
        const uri = `${process.env.REACT_APP_API_URL}/profile/forgot-password/reset`;

        try {
            const response = await fetch(uri, {
                method: 'POST',
                body: JSON.stringify(user),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                const json = await response.json();
                setError(`error.${json.message}`); 
                return;
            }
            setError('');
            setForm({
                key: '',
                newPassword: '',
                newPasswordConf: ''
            });
            navigate('/auth');
        } catch (error) {
            setError('error.unexpected');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='flex flex-col w-full sm:w-2/3 px-4'>
            <div className='flex flex-col gap-y-5'>
                <p className='text-3xl font-semibold opacity-90'>{t("settings.Change password")}</p>
                <div className='flex flex-col gap-y-4'>
                    <div>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="font-medium opacity-90">{t('reset-password.validation-key')}</span>
                            </div>
                            <OTPInput value={form.key} onChange={handleOtpChange} error={error ? error : null} />
                        </label>
                    </div>
                    <div>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="font-medium opacity-90">{t("settings.New password")}</span>
                            </div>
                            <input
                                type="password"
                                className={`input input-bordered w-full focus:input-primary ${(error && !form.newPassword) || error === 'error.2' ? 'input-error ring-1 ring-error' : ''}`}
                                value={form.newPassword}
                                onChange={(e) => setForm({ ...form, newPassword: e.target.value })}
                            />
                        </label>
                    </div>
                    <div>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="font-medium opacity-90">{t("settings.Re-type new password")}</span>
                            </div>
                            <input
                                type="password"
                                className={`input input-bordered w-full focus:input-primary ${(error && !form.newPasswordConf) || error === 'error.2' ? 'input-error ring-1 ring-error' : ''}`}
                                value={form.newPasswordConf}
                                onChange={(e) => setForm({ ...form, newPasswordConf: e.target.value })}
                            />
                        </label>
                    </div>
                </div>

                {error && (
                    <div>
                        <div className="flex items-center absolute">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-6 text-error mx-1 my-2"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <div className="text-error text-sm font-semibold mx-1 my-2">
                                {t(error)}
                            </div>
                        </div>
                    </div>
                )}

                <div className='pb-6 mt-8'>
                    <Button type="submit" loading={isLoading} disabled={isLoading} className="btn btn-primary w-full rounded-xl" onClick={handleSubmit} >{t("commons.Update")}</Button>
                </div>

                <div>
                    <Link to="../" className="btn btn-active btn-link">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                        </svg>
                        {t('reset-password.back-to-login')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ValidateResetPassword;
