import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from "react-daisyui";
import { useGlobalContext } from '../../context/GlobalProvider';
import useFetchData from '../../hooks/FetchData';
import Loading from '../../components/Loading';
import Error from '../../components/Error';

function UpdateDelivery() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { token } = useGlobalContext();
    const { id } = useParams();

    const fetchDelivery = (token) => {
        return fetch(`/api/deliveries/${id}`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        });
    };
    const { data, isLoading } = useFetchData(fetchDelivery);

    const [form, setForm] = useState({
        id: null,
        description: '',
        message: '',
        quantity: null,
    });
    const [error, setError] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (data) {
            setForm({
                id: data.id,
                description: data.description,
                message: data.message,
                quantity: data.quantity
            });
        }
    }, [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (form.quantity !== null && form.quantity <= 0) {
            return setError("error.3");
        }

        setIsUpdating(true);

        const uri = '/api/deliveries';

        try {
            const response = await fetch(uri, {
                method: 'PUT',
                body: JSON.stringify(form),
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                }
            });

            const json = await response.json();
            if (response.status === 500) {
                setError('error.unexpected');
                return;
            }
            if (!response.ok) {
                setError(json.message);
                return;
            }

            setError('');
            setForm({
                id: null,
                description: '',
                message: '',
                quantity: null,
            });
            navigate('../', { replace: true });
        } catch (error) {
            setError('error.unexpected');
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <div>
            <div className="px-10 pt-8">
                <div className="text-3xl font-medium">{t("delivery.Update delivery")}</div>
            </div>

            {isLoading || (data && Object.keys(data).length === 0)
                ? <Loading heigh="h-[70vh]" />
                : <div className='py-10 px-2 md:px-6'>
                    <div className='border rounded-2xl pt-6 bg-base-100'>
                        {/* Form starts here */}
                        <form onSubmit={handleSubmit}>
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4 p-8'>
                                <div>
                                    <label className="form-control w-full">
                                        <div className="label">
                                            <span className="label-text">{t("delivery.Description")} *</span>
                                        </div>
                                        <textarea
                                            value={form.description}
                                            onChange={(e) => setForm({ ...form, description: e.target.value })}
                                            className="textarea textarea-bordered h-24 focus:input-primary"
                                        ></textarea>
                                    </label>
                                </div>

                                <div>
                                    <label className="form-control w-full">
                                        <div className="label">
                                            <span className="label-text">{t("delivery.Message")} *</span>
                                        </div>
                                        <textarea
                                            value={form.message}
                                            onChange={(e) => setForm({ ...form, message: e.target.value })}
                                            className="textarea textarea-bordered h-24 focus:input-primary"
                                        ></textarea>
                                    </label>
                                </div>

                                <div>
                                    <label className="form-control w-full">
                                        <div className="label">
                                            <span className="label-text">N' {t("delivery.Glaciers")} *</span>
                                        </div>
                                        <input
                                            value={form.quantity}
                                            onChange={(e) => setForm({ ...form, quantity: Number(e.target.value) })}
                                            type="number"
                                            className={`input input-bordered w-full focus:input-primary ${error === "error.3" && form.quantity <= 0 && 'input-error'}`}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className={`md:flex items-center py-4 px-8 ${error ? 'justify-between' : 'justify-end'}`}>
                                {error && <Error error={error} />}
                                <div className='flex items-center gap-x-3 justify-end p-4'>
                                    <Link to="../" className='btn btn-ghost'>{t("commons.cancel")}</Link>
                                    <Button
                                        type="submit"
                                        loading={isUpdating}
                                        disabled={isUpdating}
                                        className='btn btn-primary px-8'
                                    >
                                        {t("delivery.Update delivery")}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        {/* Form ends here */}
                    </div>
                </div>}
        </div>
    );
}

export default UpdateDelivery;
