import React from 'react'

function Tab({ title, value, handleClick }) {
    return (
        <div className={`px-8 py-1 rounded-badge bg-base-200 font-semibold border-2 cursor-pointer ${ value === title ? ' text-primary border-primary' : 'text-slate-500 border-slate-500'}`} onClick={handleClick}>
            {title}
        </div>
    )
}

export default Tab