import React, { createContext, useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

const GlobalContext = createContext();
export const useGlobalContext = () => useContext(GlobalContext);

const GlobalProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    //for fill the context state in case of refresh
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            const decodedToken = jwtDecode(user.token);
            const expirationTime = decodedToken.exp;
            const currentTime = Math.floor(Date.now() / 1000);

            if (expirationTime > currentTime) {
                setUser(user.user);
                setToken(user.token);
            } else {
                localStorage.removeItem('user');
            }
        }
        setLoading(false);
    }, [])

    return (
        <GlobalContext.Provider
            value={{
                token,
                setToken,
                user,
                setUser,
                loading,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;

