import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "react-daisyui";
import { useGlobalContext } from '../../context/GlobalProvider';
import Error from '../../components/Error';
import InfinitScroll from '../../components/InfinitScroll';

function CreateDelivery() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useGlobalContext();

  const [form, setForm] = useState({
    description: '',
    message: '',
    quantity: null,
    deliveryPersonId: null,
    organizationId: null,
    clientId: null
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (form.organizationId === null || form.clientId === null) {
      return setError("error.1");
    }
    if (form.quantity !== null && form.quantity <= 0){
      return setError("error.3");
    }
    setIsLoading(true);

    const uri = '/api/deliveries';

    try {
      const response = await fetch(uri, {
        method: 'POST',
        body: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        }
      });

      const json = await response.json();
      if (response.status === 500) {
        setError('error.unexpected');
        return;
      }
      if (!response.ok) {
        setError(json.message);
        return;
      }

      setError('');
      setForm({
        description: '',
        message: '',
        quantity: null,
        deliveryPersonId: null,
        organizationId: null,
        clientId: null
      });
      navigate('../', { replace: true });
    } catch (error) {
      setError('error.unexpected');
    } finally {
      setIsLoading(false);
    }
  };

  const setDeliveryPersonId = (value) => {
    setForm({ ...form, deliveryPersonId: value });
  };

  const setOrganizationId = (value) => {
    setForm({ ...form, organizationId: value });
  };

  const setClientId = (value) => {
    setForm({ ...form, clientId: value });
  };

  return (
    <div>
      <div className='flex text-lg items-center px-8 w-min hover:underline font-medium'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 me-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
        <Link to="../">{t("delivery.Deliveries")}</Link>
      </div>

      <div className="px-8 pt-4">
        <div className="text-3xl font-medium">{t("delivery.Create delivery")}</div>
      </div>

      <div className='py-10 px-2 md:px-6'>
        <div className='border rounded-2xl pt-6 bg-base-100'>
          {/* Form starts here */}
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4 p-8'>
              <InfinitScroll
                value={form.organizationId}
                setValue={setOrganizationId}
                label="Organisation"
                data="organizations"
              />

              <InfinitScroll
                value={form.clientId}
                setValue={setClientId}
                label="Client"
                data="clients"
              />

              <InfinitScroll
                value={form.deliveryPersonId}
                setValue={setDeliveryPersonId}
                label="Delivery Agent"
                data="app-users"
                deliveryAgent
              />

              <div></div>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4 p-8'>
              <div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t("delivery.Description")}</span>
                  </div>
                  <textarea
                    value={form.description}
                    onChange={(e) => setForm({ ...form, description: e.target.value })}
                    className="textarea textarea-bordered h-24 focus:input-primary"
                  ></textarea>
                </label>
              </div>

              <div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t("delivery.Message")}</span>
                  </div>
                  <textarea
                    value={form.message}
                    onChange={(e) => setForm({ ...form, message: e.target.value })}
                    className="textarea textarea-bordered h-24 focus:input-primary"
                  ></textarea>
                </label>
              </div>

              <div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">N' {t("delivery.Glaciers")}</span>
                  </div>
                  <input
                    value={form.quantity}
                    onChange={(e) => setForm({ ...form, quantity: e.target.value ? Number(e.target.value) : null })}
                    type="number"
                    className={`input input-bordered w-full focus:input-primary ${error === "error.3" && form.quantity <= 0 && 'input-error'}`}
                  />
                </label>
              </div>
            </div>

            <div className={`md:flex items-center py-4 px-8 ${error ? 'justify-between' : 'justify-end'}`}>
              {error && <Error error={error} />}
              <div className='flex justify-end gap-x-3'>
                <Link to="../" className='btn btn-ghost'>{t("commons.cancel")}</Link>
                <Button
                  type="submit"
                  loading={isLoading}
                  disabled={isLoading}
                  className='btn btn-primary px-8'
                >
                  {t("delivery.Create delivery")}
                </Button>
              </div>
            </div>
          </form>
          {/* Form ends here */}
        </div>
      </div>
    </div>
  );
}

export default CreateDelivery;
