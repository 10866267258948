import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { icons } from "../constants"

function NotFound() {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col h-screen gap-y-6 items-center justify-center'>
      <img alt="404" src={icons.notFound} className='w-80' />
      <div className='text-4xl font-medium'>{t('not-found.title')}</div>
      <div className='opacity-60'>{t('not-found.sub-title')}</div>
      <Link to="/admin" className='btn btn-primary'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
        {t('not-found.bach-home')}
      </Link>
    </div>
  )
}

export default NotFound