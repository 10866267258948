import { Outlet } from "react-router-dom";

function DeliveryLayout() {
  return (
    <div className="py-24">
      <Outlet />
    </div>
  )
}

export default DeliveryLayout