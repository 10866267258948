import React from 'react';
import { useTranslation } from 'react-i18next';

function DeliveryStatus({ status }) {
    const { t } = useTranslation();

    const colors = {
        IN_PROGRESS: { bg: 'border-warning', text: 'text-warning' },
        AVAILABLE: { bg: 'border-success', text: 'text-success' },
        DELIVERED: { bg: 'border-primary', text: 'text-primary' },
        ASSIGNED: { bg: 'border-secondary', text: 'text-secondary' }
    };

    const color = colors[status] || { bg: 'bg-primary-100', text: 'text-primary-600' };

    return (
        <div className={`badge ${color.bg} py-2`}>
            <div className={`text-sm font-bold ${color.text}`}>
                {t(`commons.${status}`)}
            </div>
        </div>
    );
}

export default DeliveryStatus;
