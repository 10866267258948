import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfinitScroll from '../../components/InfinitScroll';
import { Button } from "react-daisyui"
import { useGlobalContext } from '../../context/GlobalProvider';
import Error from '../../components/Error';

function CreateReturns() {
  const { t } = useTranslation();
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const [imagePreview1, setImagePreview1] = useState(null);
  const [imagePreview2, setImagePreview2] = useState(null);
  const [fileData1, setFileData1] = useState(null);
  const [fileData2, setFileData2] = useState(null);
  const navigate = useNavigate();
  const { token } = useGlobalContext();
  const [quantities, setQuantities] = useState([{ organizationId: null, quantity: null }]);
  const [deliveryPersonId, setDeliveryPersonId] = useState(null);
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleFileChange = (event, setImagePreview, setFileData) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setFileData(file);
    }
  };

  const addPackage = () => {
    setQuantities([...quantities, { organizationId: null, quantity: null }]);
  };

  const handleInputChange = (index, field, value) => {
    setQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = {
        ...newQuantities[index],
        [field]: value
      };
      return newQuantities;
    });
  };

  const infinitScrollSetDeliveryPersonId = (value, index) => {
    handleInputChange(index, 'organizationId', value);
  };

  const deletePackage = (index) => {
    setQuantities(prevQuantities => prevQuantities.filter((_, i) => i !== index));
  };

  const checkQuantities = () => {
    return quantities.every(pkg => pkg.organizationId !== null && pkg.quantity !== null && pkg.quantity !== '');
  };

  const validateQuantities = () => {
    return quantities.every(pkg => pkg.quantity > 0);
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (deliveryPersonId === null || !checkQuantities()
      || fileData1 === null) {
      return setError("error.1");
    }
    if(!validateQuantities()){
      return setError("error.3")
    }

    setIsLoading(true);

    const uri = '/api/cooler-returns/admin';
    const dto = { deliveryPersonId, quantities };
    const formData = new FormData();
    formData.append('coolerReturn', new Blob([JSON.stringify(dto)], { type: 'application/json' }));
    fileData1 && formData.append('image1', fileData1);
    fileData2 && formData.append('image2', fileData2);

    console.log(formData)

    try {
      const response = await fetch(uri, {
        method: 'POST',
        body: formData,
        headers: {
          'authorization': `Bearer ${token}`
        }
      });

      const json = await response.json();
      if (response.status === 500) {
        setError('error.unexpected');
        return;
      }
      if (!response.ok) {
        setError(json.message);
        return;
      }
      setError('')
      setDeliveryPersonId(null)
      setQuantities([{ organizationId: null, quantity: null }])
      navigate('../', { replace: true });
    } catch (error) {
      setError('error.unexpected');
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <div className='flex text-lg items-center px-8 w-min hover:underline font-medium'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 me-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
        <Link to="../">{t("return.Returns")}</Link>
      </div>

      <div className="px-8 pt-4">
        <div className="text-3xl font-medium">{t("return.Create return")}</div>
      </div>

      <div className='py-10 px-2 lg:px-8'>
        <div className='border rounded-2xl pt-6 bg-base-100'>
          {/* form begins here */}
          <form onSubmit={handleSubmit}>
            {quantities.map((pkg, index) => (
              <div key={index} className='flex flex-col md:flex-row items-center gap-x-6 gap-y-4 px-8 pt-8 pb-4'>
                <div className="w-full md:w-1/2">
                  <InfinitScroll
                    value={pkg.organizationId}
                    setValue={(value) => infinitScrollSetDeliveryPersonId(value, index)}
                    label="Organisation"
                    data="organizations"
                  />
                </div>

                <div className="w-full md:w-1/2">
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">N' {t("delivery.Glaciers")} *</span>
                    </div>
                    <input
                      type="number"
                      className={`input input-bordered w-full focus:input-primary ${error === "error.3" && pkg.quantity <= 0 && 'input-error'}`}
                      value={pkg.quantity || ''}
                      onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                    />
                  </label>
                </div>

                {quantities.length > 1 && (
                  <div className='flex justify-end w-full md:w-auto md:mt-9'>
                    <button className='btn btn-error text-base-100' onClick={() => deletePackage(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                        <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            ))}

            <div className='flex justify-end px-8'>
              <button className='btn btn-link btn-primary' onClick={addPackage}>
                {t("return.addPackage")}
              </button>
            </div>

            <div className='flex flex-col md:flex-row gap-x-6 gap-y-4 p-8 mt-16 mb-8'>

              <div className="w-full md:w-1/2">
                <InfinitScroll
                  value={deliveryPersonId}
                  setValue={setDeliveryPersonId}
                  label="Delivery Agent"
                  data="app-users"
                  deliveryAgent
                />
              </div>

              <div className='w-full md:w-1/2 flex items-center gap-4 px-6 justify-end'>
                <div
                  className="relative p-[80px] rounded-xl border-2 border-dashed flex items-center justify-center cursor-pointer"
                  onClick={() => fileInputRef1.current.click()}
                >
                  {imagePreview1 ? (
                    <>
                      <img src={imagePreview1} alt='client' className="absolute w-full h-full object-cover rounded-xl opacity-70" />
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 z-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                      </svg>
                    </>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                    </svg>
                  )}
                  <input type='file' ref={fileInputRef1} hidden onChange={(event) => handleFileChange(event, setImagePreview1, setFileData1)} accept="image/png, image/jpeg" />
                </div>

                {imagePreview1 && <div
                  className="relative p-[80px] rounded-xl border-2 border-dashed flex items-center justify-center cursor-pointer"
                  onClick={() => fileInputRef2.current.click()}
                >
                  {imagePreview2 ? (
                    <>
                      <img src={imagePreview2} alt='client' className="absolute w-full h-full object-cover rounded-xl opacity-70" />
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 z-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                      </svg>
                    </>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                    </svg>
                  )}
                  <input type='file' ref={fileInputRef2} hidden onChange={(event) => handleFileChange(event, setImagePreview2, setFileData2)} accept="image/png, image/jpeg" />
                </div>}
              </div>

            </div>

            <div className={`md:flex items-center py-4 px-8 ${error ? 'justify-between' : 'justify-end'}`}>
              {error &&
                <Error error={error} />
              }
              <div className='flex justify-end gap-x-3'>
                <Link to="../" className='btn btn-ghost'>{t("commons.cancel")}</Link>
                <Button loading={isLoading} disabled={isLoading} className='btn btn-primary px-8' type='submit'>{t("return.Create return")}</Button>
              </div>
            </div>
          </form>
          {/* form ends here */}
        </div>
      </div>
    </div>
  );
}

export default CreateReturns;
