import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFetchData from '../../hooks/FetchData';
import Loading from '../../components/Loading'
import NoContent from '../../components/NoContent'
import IdsPagination from '../../components/Pagination';
import InfinitScroll from '../../components/InfinitScroll';
import { Modal, Button } from 'react-daisyui';
import { format, parseISO } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { useGlobalContext } from '../../context/GlobalProvider';
import Alert from '../../components/Alert';

function Returns() {

  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [search, setSearch] = useState('')
  const [sortOrder, setSortOrder] = useState('desc');
  const [after, setAfter] = useState('')
  const [before, setBefore] = useState('')
  const [deliveryPerson, setDeliveryPerson] = useState(null)
  const [organization, setOrganization] = useState(null)
  const [isExporting, setIsExporting] = useState(false)
  const { token } = useGlobalContext()
  const [error, setError] = useState()
  const [showAlert, setShowAlert] = useState(false);

  const fetchReturns = (token) => {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      sort: `created,${sortOrder}`,
    });

    if (search) {
      params.append('search', search);
    }

    if (organization) {
      params.append('organizationId', organization);
    }

    if (deliveryPerson) {
      params.append('doneBy', deliveryPerson);
    }

    if (after) {
      params.append('startsAfter', after);
    }

    if (before) {
      params.append('startsBefore', before);
    }

    return fetch(`/api/cooler-returns?${params.toString()}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  };

  const { data, isLoading, total } = useFetchData(fetchReturns, [page, size, sortOrder, search, before, after, deliveryPerson, organization]);

  const handleExport = async () => {
    setIsExporting(true)
    try {
      const response = await fetch(`/api/cooler-returns/export`, {
        headers: {
          'authorization': `Bearer ${token}`
        }
      });
      if (response.status === 500) {
        setError(t('error.unexpected'));
        setShowAlert(true)
        return;
      }
      const blob = await response.blob(); // Get the response as a blob
      const url = window.URL.createObjectURL(blob); // Create a URL for the blob
      const a = document.createElement('a'); // Create an <a> element
      a.href = url;
      const today = new Date();
      const formattedDate = new Intl.DateTimeFormat('en-GB').format(today);
      a.download = 'retours_' + formattedDate;
      document.body.appendChild(a);
      a.click(); // Simulate a click to trigger the download
      document.body.removeChild(a); // Clean up
      window.URL.revokeObjectURL(url); // Release the URL object
    } catch {
      setError('error.unexpected');
    } finally {
      setIsExporting(false)
    }
  }

  const handleSortChange = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle order
    setPage(0); // Reset page to fetch from the beginning
  };

  const ref = useRef(null);

  const handleShow = useCallback(() => {
    ref.current?.showModal();
  }, [ref]);

  const clearFilters = () => {
    setDeliveryPerson(null)
    setOrganization(null)
    setBefore('')
    setAfter('')
  }

  const getLocale = () => {
    switch (i18n.language) {
      case 'fr':
        return fr;
      case 'en':
      default:
        return enUS;
    }
  };

  return (
    <div>
      {/* header */}
      <div className="flex justify-between py-8 px-4 lg:px-8">
        <div>
          <div className="text-4xl font-medium">{t("return.Returns")}</div>
          <div className='flex gap-2 mt-2'>
            <div className="tooltip tooltip-right" data-tip={t("commons.v2tooltip")}>
              <button className='btn btn-ghost btn-sm'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
                {t("commons.import")}
              </button>
            </div>
            <Button className='btn btn-ghost btn-sm' disabled={isExporting} loading={isExporting} onClick={handleExport}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
              </svg>
              {t("commons.export")}
            </Button>
          </div>
        </div>
        <div>
          <Link to="create" className='btn btn-primary rounded-2xl'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            {t("commons.Add")}
          </Link>
        </div>
      </div>

      {/* content */}
      <div className='py-6 px-4 lg:px-6'>
        {/* content header */}
        <div className='p-4 border rounded-2xl mb-4 flex justify-between items-center'>

          <label className="input input-bordered focus-within:input-primary flex items-center w-full md:w-7/12 lg:w-1/2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
            <input type="text" className="grow" placeholder="idsRef" value={search} onChange={(e) => { setSearch(e.target.value); setPage(0); }} />
          </label>

          <div className='hidden md:flex items-center gap-2'>
            <div>
              <Button onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                </svg>
                {t("commons.Filters")}
              </Button>
              <Modal ref={ref}>
                <Modal.Header className="font-bold flex items-center gap-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                  </svg>
                  {t("commons.Filters")}
                </Modal.Header>
                <Modal.Body>
                  <div className='flex flex-col gap-y-4 p-6'>

                    <InfinitScroll
                      setValue={setDeliveryPerson}
                      label="Delivery Agent"
                      data="app-users"
                      deliveryAgent
                    />

                    <InfinitScroll
                      setValue={setOrganization}
                      label="Organisation"
                      data="organizations"
                    />

                    <label className="form-control">
                      <div className="label">
                        <span className="label-text font-semibold">{t("commons.After")}</span>
                      </div>
                      <input
                        type="date"
                        value={after}
                        onChange={(e) => { setAfter(e.target.value); setPage(0); }}
                        className="input input-sm input-bordered focus:input-primary"
                      />
                    </label>

                    <label className="form-control">
                      <div className="label">
                        <span className="label-text font-semibold">{t("commons.Before")}</span>
                      </div>
                      <input
                        type="date"
                        value={before}
                        onChange={(e) => { setBefore(e.target.value); setPage(0); }}
                        className="input input-sm input-bordered focus:input-primary"
                      />
                    </label>

                  </div>
                </Modal.Body>
                <Modal.Actions>
                  <form method="dialog" className='flex justify-end items-center gap-x-2'>
                    <Button className='btn-primary'>{t("commons.apply")}</Button>
                    <Button onClick={clearFilters}>{t("commons.clear")}</Button>
                  </form>
                </Modal.Actions>
              </Modal>
            </div>

            <select
              className="select select-bordered focus:select-primary"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="desc">{t("commons.Newest")}</option>
              <option value="asc">{t("commons.Oldest")}</option>
            </select>
          </div>
        </div>

        {isLoading ?
          <Loading heigh="h-[45vh]" />
          : (data && Object.keys(data).length !== 0)
            ?
            <>
              <div className="overflow-x-auto border rounded-tl-2xl rounded-tr-2xl">
                <table className="table table-lg w-full min-w-[640px] text-center">
                  {/* head */}
                  <thead className='bg-base-200'>
                    <tr >
                      <th>#</th>
                      <th>{t("commons.delivery agent")}</th>
                      <th>{t("commons.Return Date")}</th>
                      <th>N' {t("return.Packages")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      // rows
                      <tr key={index}>
                        <td>
                          <p className='badge badge-sm badge-ghost font-medium'>{item.idsRef}</p>
                        </td>
                        <td>
                          {item.deliveryPersonName}
                        </td>
                        <td>
                          <span className="badge badge-ghost badge-sm">{item.deliveryTime ? format(parseISO(item.deliveryTime), 'PPp', { locale: getLocale() }) : ''}</span>
                        </td>
                        <td>{item.quantities.length}</td>
                        <th>
                          <Link to={`${item.id}`} className="btn btn-ghost btn-sm"><span className='text-sm'>{t("commons.details")}</span></Link>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* pagination */}
              <IdsPagination
                total={total}
                page={page}
                size={size}
                setPage={setPage}
                setSize={setSize}
              />
            </>
            :
            <NoContent />
        }

      </div>
      {/* Alert component */}
      <Alert message={error} type="alert-error" showAlert={showAlert} setShowAlert={setShowAlert} />
    </div>
  )
}

export default Returns