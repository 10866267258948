import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from "react-daisyui";
import { useGlobalContext } from '../context/GlobalProvider';
import Error from '../components/Error';
import Alert from '../components/Alert';

function Settings() {

  const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "light")
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { token, user } = useGlobalContext();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    localStorage.setItem('language', lng);
  };

  useEffect(() => {
    localStorage.setItem("theme", theme)
    const localTheme = localStorage.getItem("theme")
    document.querySelector("html").setAttribute("data-theme", localTheme)
  }, [theme])

  const [form, setForm] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConf: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleSubmit = async () => {
    if (form.oldPassword === "" || form.newPassword === ""
      || form.newPasswordConf === "") {
      return setError("error.1");
    }
    if (form.newPassword !== form.newPasswordConf) {
      return setError("error.2");
    }
    setIsLoading(true);

    const { oldPassword, newPassword } = form;
    const dto = { oldPassword, newPassword };

    try {
      const response = await fetch(`/api/profile/${user.id}/reset-password`, {
        method: 'POST',
        body: JSON.stringify(dto),
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        }
      });

      const json = await response.json();
      if (response.status === 500) {
        setError('error.unexpected');
        return;
      }
      if (!response.ok) {
        setError(json.message);
        return;
      }

      setError('');
      setForm({
        oldPassword: '',
        newPassword: '',
        newPasswordConf: ''
      });
      
      setAlertMessage(t("commons.password-change"));
      setAlertType('alert-success');
      setShowAlert(true);
    } catch (error) {
      setError('error.unexpected');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='py-24'>

      <div className="pt-8 px-10">
        <div className="text-3xl font-medium">{t("commons.Settings")}</div>
      </div>

      <div className='py-10 px-2 lg:px-8'>

        <div className='border rounded-2xl pt-6'>
          <div className='flex items-center gap-4 px-6'>
            <div className='p-2 rounded-full shadow-xl'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
              </svg>
            </div>
            <div className='text-xl font-medium'>
              {t("settings.Change password")}
            </div>
          </div>
          <div className='flex flex-col gap-y-4 p-8' >
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("settings.Old password")}</span>
                </div>
                <input
                  type="password"
                  className={`input input-bordered w-full focus:input-primary ${(error && !form.oldPassword) || error === 'error.E015' ? 'input-error ring-1 ring-error' : ''}`}
                  value={form.oldPassword}
                  onChange={(e) => setForm({ ...form, oldPassword: e.target.value })}
                />
              </label>
            </div>
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("settings.New password")}</span>
                </div>
                <input
                  type="password"
                  className={`input input-bordered w-full focus:input-primary ${(error && !form.newPassword) || error === 'error.2' ? 'input-error ring-1 ring-error' : ''}`}
                  value={form.newPassword}
                  onChange={(e) => setForm({ ...form, newPassword: e.target.value })}
                />
              </label>
            </div>
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("settings.Re-type new password")}</span>
                </div>
                <input
                  type="password"
                  className={`input input-bordered w-full focus:input-primary ${(error && !form.newPasswordConf) || error === 'error.2' ? 'input-error ring-1 ring-error' : ''}`}
                  value={form.newPasswordConf}
                  onChange={(e) => setForm({ ...form, newPasswordConf: e.target.value })}
                />
              </label>
            </div>
          </div>
          <div className={`md:flex items-center py-4 px-8 ${error ? 'justify-between' : 'justify-end'}`}>
            {error &&
              <Error error={error} />
            }
            <Button loading={isLoading} disabled={isLoading} className='btn btn-primary px-8' onClick={handleSubmit}>{t("commons.Update")}</Button>
          </div>
        </div>

        <div className='border rounded-2xl pt-6 my-6 '>
          <div className='flex items-center gap-4 px-6'>
            <div className='p-2 rounded-full shadow-xl'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
            </div>
            <div className='text-xl font-medium'>
              Theme options
            </div>
          </div>
          <div className='py-8 px-6' >
            <div className='border  rounded-2xl'>
              <div className="form-control">
                <label className="label cursor-pointer p-3">
                  <div className='flex items-center gap-4'>
                    <div className="avatar placeholder">
                      <div className="bg-neutral text-neutral-content rounded-full w-11">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                        </svg>
                      </div>
                    </div>
                    <div>
                      <p className='font-medium'>{t("settings.Light mode")}</p>
                      <p className='text-sm opacity-70'>{t("settings.light-mode-desc")}</p>
                    </div>
                  </div>
                  <input type="radio" name="theme-mode" className="radio radio-primary" checked={theme === 'light'} onChange={() => setTheme("light")} />
                </label>
              </div>

              <div className="form-control">
                <label className="label cursor-pointer border-y p-3">
                  <div className='flex items-center gap-4'>
                    <div className="avatar placeholder">
                      <div className="bg-neutral text-neutral-content rounded-full w-11">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
                        </svg>
                      </div>
                    </div>
                    <div>
                      <p className='font-medium'>{t("settings.Dark mode")}</p>
                      <p className='text-sm opacity-70'>{t("settings.dark-mode-desc")}</p>
                    </div>
                  </div>
                  <input type="radio" name="theme-mode" className="radio radio-primary" checked={theme === 'dark'} onChange={() => setTheme("dark")} />
                </label>
              </div>

              <div className="form-control pointer-events-none opacity-50 filter grayscale">
                <label className="label cursor-pointer p-3">
                  <div className='flex items-center gap-4'>
                    <div className="avatar placeholder">
                      <div className="bg-neutral text-neutral-content rounded-full w-11">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                        </svg>
                      </div>
                    </div>
                    <div>
                      <p className='font-medium'>{t("settings.System")}</p>
                      <p className='text-sm opacity-70'>{t("settings.system-desc")}</p>
                    </div>
                  </div>
                  <input type="radio" name="theme-mode" className="radio radio-primary" checked={theme === 'system'} onChange={() => setTheme("light")} />
                </label>
              </div>

            </div>
          </div>
        </div>

        <div className='border rounded-2xl pt-6 my-6 '>
          <div className='flex items-center gap-4 px-6'>
            <div className='p-2 rounded-full shadow-xl'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
              </svg>
            </div>
            <div className='text-xl font-medium'>
              {t("settings.Language")}
            </div>
          </div>
          <div className='py-8 px-6' >
            <div className='border  rounded-2xl'>

              <div className="form-control">
                <label className="label cursor-pointer border-b p-3">
                  <div className='flex items-center gap-4'>
                    <div className="avatar placeholder">
                      <div className="bg-neutral text-neutral-content rounded-full w-11 font-semibold">
                        EN
                      </div>
                    </div>
                    <div>
                      <p className='font-medium'>English</p>
                    </div>
                  </div>
                  <input type="radio" name="lang-mode" className="radio radio-primary" checked={selectedLanguage === 'en'} onChange={() => changeLanguage('en')} />
                </label>
              </div>

              <div className="form-control">
                <label className="label cursor-pointer p-3">
                  <div className='flex items-center gap-4'>
                    <div className="avatar placeholder">
                      <div className="bg-neutral text-neutral-content rounded-full w-11 font-semibold">
                        FR
                      </div>
                    </div>
                    <div>
                      <p className='font-medium'>Français</p>
                    </div>
                  </div>
                  <input type="radio" name="lang-mode" className="radio radio-primary" checked={selectedLanguage === 'fr'} onChange={() => changeLanguage('fr')} />
                </label>
              </div>

            </div>
          </div>
        </div>

        <div className='border rounded-2xl p-6'>
          <div className='flex items-center gap-4'>
            <div className='p-2 rounded-full shadow-lg'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
              </svg>
            </div>
            <div className='text-xl font-medium'>
              {t("settings.Delete account")}
            </div>
          </div>
          <div className='mt-7 mb-1'>
            <button className='btn btn-error text-base-100'>{t("settings.Delete account")}</button>
            <div className='text-sm opacity-75 mt-4'>
              {t("settings.delete-account-warning")}
            </div>
          </div>
        </div>

      </div>

      {/* Alert component */}
      <Alert message={alertMessage} type={alertType} showAlert={showAlert} setShowAlert={setShowAlert} />

    </div>
  )
}

export default Settings