import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useGlobalContext } from "../context/GlobalProvider";
import { Button, Checkbox } from "react-daisyui";

function Login() {
  const { t } = useTranslation();
  const { setUser, setToken } = useGlobalContext();
  const [form, setForm] = useState({
    email: '',
    password: '',
    rememberMe: false,
    alert: false
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (form.email === "" || form.password === "") {
      return setError("error.1");
    }
    setIsLoading(true);

    const { email, password, rememberMe } = form;
    const user = { username: email, password, rememberMe };
    const uri = '/api/authenticate';

    try {
      const response = await fetch(uri, {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const json = await response.json();
      if (!response.ok) {
        setError(`error.${json.detail}`);
        return;
      }

      // Save the user to local storage
      const tkn = {
        user: json.user,
        token: json.token
      };
      localStorage.setItem('user', JSON.stringify(tkn));

      // Updating the context
      setUser(tkn.user);
      setToken(tkn.token);

      setError('');
      setForm({
        email: '',
        password: ''
      });
      navigate('/admin', { replace: true });
    } catch (error) {
      setError('error.unexpected');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='flex flex-col w-full sm:w-2/3 lg:w-3/4 xl:w-2/3 px-6 md:px-0'>
      <p className='text-3xl font-semibold opacity-90 my-2'>{t('login.title')}</p>

      {/* Form starts here */}
      <form onSubmit={handleSubmit} className='flex flex-col gap-y-1'>
        <div>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">{t('commons.Email')}</span>
            </div>
            <input
              type="email"
              className={`input input-bordered w-full focus:input-primary ${error && !form.email ? 'input-error ring-1 ring-error' : ''}`}
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </label>
        </div>

        <div>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">{t('login.pw')}</span>
            </div>
            <input
              type="password"
              className={`input input-bordered w-full focus:input-primary ${error && !form.password ? 'input-error ring-1 ring-error' : ''}`}
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
          </label>
        </div>

        {error && (
          <div>
            <div className="flex items-center absolute">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6 text-error mx-1 my-2"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="text-error text-sm font-semibold mx-1 my-2">
                {t(error)}
              </div>
            </div>
          </div>
        )}

        <div className='pb-6 mt-10'>
          <Button type="submit" loading={isLoading} disabled={isLoading} className="btn btn-primary w-full rounded-xl">
            {t('login.L-button')}
          </Button>
        </div>

        <div className="flex items-center justify-between">
          <div>
            <Link to="reset-password" className="btn btn-active btn-link">{t('login.fw')}</Link>
          </div>
          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text font-medium mr-2">{t(`commons.remember-me`)}</span>
              <Checkbox
                checked={form.rememberMe}
                className="checkbox checkbox-primary checkbox-sm"
                onChange={() => setForm({ ...form, rememberMe: !form.rememberMe })}
              />
            </label>
          </div>
        </div>
      </form>
      {/* Form ends here */}
    </div>
  );
}

export default Login;
