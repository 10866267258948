import React, { useState } from 'react';

function OTPInput({ value, onChange, error }) {
    const [otp, setOtp] = useState(new Array(5).fill("")); // Change the length to 5

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        const newOtp = [...otp.map((d, idx) => (idx === index ? element.value : d))];
        setOtp(newOtp);

        // Update the parent component's state with the concatenated OTP string
        onChange(newOtp.join(""));

        // Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    return (
        <div className="flex space-x-2">
            {otp.map((data, index) => {
                return (
                    <input
                        type="text"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={e => handleChange(e.target, index)}
                        onFocus={e => e.target.select()}
                        className={`input input-bordered w-12 h-12 text-xl text-center focus:input-primary ${(error && !data) || error === 'error.E021' ? 'input-error ring-1 ring-error' : ''}`}
                    />
                );
            })}
        </div>
    );
}

export default OTPInput;
