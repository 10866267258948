import { Outlet } from "react-router-dom"

function DeliveryAgentLayout() {
    return (
        <div className="py-24">
            <Outlet />
        </div>
    )
}

export default DeliveryAgentLayout