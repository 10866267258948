import { createBrowserRouter, RouterProvider, Route, createRoutesFromElements, Navigate } from "react-router-dom";
import React, { useEffect } from 'react'

// layouts
import AdminLayout from "./layouts/AdminLayout";
import ClientsLayout from "./layouts/ClientsLayout";
import AuthLayout from "./layouts/AuthLayout";
import AppLayout from "./layouts/AppLayout";
import CouriersLayout from "./layouts/CouriersLayout";
import DeliveryLayout from "./layouts/DeliveryLayout";
import ReturnsLayout from "./layouts/ReturnsLayout";
import OrganizationsLayout from "./layouts/OrganizationsLayout";
import DeliveryAgentLayout from "./layouts/DeliveryAgentLayout"

// pages
import Dashboard from "./pages/Dashboard";

import Clients from "./pages/Clients/Clients";
import CreateClient from "./pages/Clients/CreateClient";
import ClientDetails from "./pages/Clients/ClientDetails";
import UpdateClient from "./pages/Clients/UpdateClient";

import Couriers from "./pages/Couriers/Couriers";
import CourierDetails from "./pages/Couriers/CourierDetails";
import UpdateCourier from "./pages/Couriers/UpdateCourier";
import CreateCourier from "./pages/Couriers/CreateCourier";

import Deliveries from "./pages/Delivery/Deliveries";
import DeliveryDetails from "./pages/Delivery/DeliveryDetailes";
import UpdateDelivery from "./pages/Delivery/UpdateDelivery";
import CreateDelivery from "./pages/Delivery/CreateDelivery";

import Returns from "./pages/Returns/Returns";
import ReturnDetails from "./pages/Returns/ReturnDetailes";
import UpdateReturn from "./pages/Returns/UpdateReturn";
import CreateReturn from "./pages/Returns/CreateReturns"


import Organisations from "./pages/Organizations/Organisations";
import OrganisationDetails from "./pages/Organizations/OrganisationDetails";
import UpdateOrganisation from "./pages/Organizations/UpdateOrganisation";
import CreateOrganisation from "./pages/Organizations/CreateOrganisation"

import NotFound from "./pages/NotFound";
import Profile from "./pages/Profile"
import Settings from "./pages/Settings"

import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Punch from "./pages/Punch";

import DeliveryAgents from "./pages/DeliveryAgents/DeliveryAgents";
import DeliveryAgentDetails from "./pages/DeliveryAgents/DeliveryAgentDetails";
import CreateDeliveryAgent from './pages/DeliveryAgents/CreateDeliveryAgent';
import UpdateDeliveryAgent from "./pages/DeliveryAgents/UpdateDeliveryAgent";
import { useGlobalContext } from "./context/GlobalProvider";
import ValidateResetPassword from "./pages/ValidateResetPassword";
import Loading from "./components/Loading";

function App() {
  const { user, loading } = useGlobalContext()

  useEffect(() => {
    const localTheme = localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
    localStorage.setItem("theme", localTheme)
    document.querySelector("html").setAttribute("data-theme", localTheme)
  }, [])

  if (loading) {
    return <Loading heigh="h-[100vh]" />;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<AppLayout />}>

        <Route index element={user ? <Navigate to="/admin" replace /> : <Navigate to="/auth" replace />} />

        <Route path="admin" element={<AdminLayout />} >

          <Route index element={user ? <Dashboard /> : <Navigate to="/auth" replace />} />

          <Route path="clients" element={user ? <ClientsLayout /> : <Navigate to="/auth" replace />}>
            <Route index element={user ? <Clients /> : <Navigate to="/auth" replace />} />
            <Route path=":id" element={user ? <ClientDetails /> : <Navigate to="/auth" replace />} />
            <Route path=":id/update" element={user ? <UpdateClient /> : <Navigate to="/auth" replace />} />
            <Route path="create" element={user ? <CreateClient /> : <Navigate to="/auth" replace />} />
          </Route>

          <Route path="couriers" element={<CouriersLayout />}>
            <Route index element={user ? <Couriers /> : <Navigate to="/auth" replace />} />
            <Route path=":id" element={user ? <CourierDetails /> : <Navigate to="/auth" replace />} />
            <Route path=":id/update" element={user ? <UpdateCourier /> : <Navigate to="/auth" replace />} />
            <Route path="create" element={user ? <CreateCourier /> : <Navigate to="/auth" replace />} />
          </Route>

          <Route path="delivery-agents" element={<DeliveryAgentLayout />}>
            <Route index element={user ? <DeliveryAgents /> : <Navigate to="/auth" replace />} />
            <Route path=":id" element={user ? <DeliveryAgentDetails /> : <Navigate to="/auth" replace />} />
            <Route path=":id/update" element={user ? <UpdateDeliveryAgent /> : <Navigate to="/auth" replace />} />
            <Route path="create" element={user ? <CreateDeliveryAgent /> : <Navigate to="/auth" replace />} />
          </Route>

          <Route path="deliveries" element={<DeliveryLayout />}>
            <Route index element={user ? <Deliveries /> : <Navigate to="/auth" replace />} />
            <Route path=":id" element={user ? <DeliveryDetails /> : <Navigate to="/auth" replace />} />
            <Route path=":id/update" element={user ? <UpdateDelivery /> : <Navigate to="/auth" replace />} />
            <Route path="create" element={user ? <CreateDelivery /> : <Navigate to="/auth" replace />} />
          </Route>

          <Route path="returns" element={<ReturnsLayout />}>
            <Route index element={user ? <Returns /> : <Navigate to="/auth" replace />} />
            <Route path=":id" element={user ? <ReturnDetails /> : <Navigate to="/auth" replace />} />
            <Route path=":id/update" element={user ? <UpdateReturn /> : <Navigate to="/auth" replace />} />
            <Route path="create" element={user ? <CreateReturn /> : <Navigate to="/auth" replace />} />
          </Route>

          <Route path="organisations" element={<OrganizationsLayout />}>
            <Route index element={user ? <Organisations /> : <Navigate to="/auth" replace />} />
            <Route path=":id" element={user ? <OrganisationDetails /> : <Navigate to="/auth" replace />} />
            <Route path=":id/update" element={user ? <UpdateOrganisation /> : <Navigate to="/auth" replace />} />
            <Route path="create" element={user ? <CreateOrganisation /> : <Navigate to="/auth" replace />} />
          </Route>

          <Route path="profile" element={user ? <Profile /> : <Navigate to="/auth" replace />} />

          <Route path="settings" element={user ? <Settings /> : <Navigate to="/auth" replace />} />

          <Route path="punch" element={user ? <Punch /> : <Navigate to="/auth" replace />} />

        </Route>

        <Route path="auth" element={<AuthLayout />}>
          <Route index element={!user ? <Login /> : <Navigate to="/admin" replace />} />
          <Route path="reset-password" element={!user ? <ResetPassword /> : <Navigate to="/admin" replace />} />
          <Route path="validate-reset-password" element={!user ? <ValidateResetPassword /> : <Navigate to="/admin" replace />} />
        </Route>

        <Route path="*" element={user ? <NotFound /> : <Navigate to="/auth" replace />} />

      </Route>
    )
  )

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
