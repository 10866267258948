import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function UpdateReturn() {

  const { t } = useTranslation();

  return (
    <div>

      <div className="px-10 pt-8">
        <div className="text-3xl font-medium">{t("return.Update return")}</div>
      </div>

      <div className='p-10'>

        <div className='border rounded-2xl pt-6 bg-base-100'>

          <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4 p-8'>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.Organisation")} *</span>
                </div>
                <select className="select select-bordered focus:select-primary">
                  <option selected>AliAmeur</option>
                  <option>Star Wars</option>
                  <option>Harry Potter</option>
                  <option>Lord of the Rings</option>
                  <option>Planet of the Apes</option>
                  <option>Star Trek</option>
                </select>
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.Return Date")} *</span>
                  <span className="label-text text-[10px] font-bold opacity-60">MM/DD/YYYY</span>
                </div>
                <input type="date" className="input input-bordered w-full focus:input-primary" defaultValue="2024-05-12" />
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.Type")} *</span>
                </div>
                <select className="select select-bordered focus:select-primary">
                  <option selected>{t("return.Delivery agent")}</option>
                  <option>{t("return.Courtier")}</option>
                </select>
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t("commons.Delivery Agent")} *</span>
                </div>
                <select className="select select-bordered focus:select-primary">
                  <option selected>Ali Ameur</option>
                  <option>Star Wars</option>
                  <option>Harry Potter</option>
                  <option>Lord of the Rings</option>
                  <option>Planet of the Apes</option>
                  <option>Star Trek</option>
                </select>
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">N' {t("delivery.Glaciers")} *</span>
                </div>
                <input type="number" className="input input-bordered w-full focus:input-primary" defaultValue="12" />
              </label>
            </div>

          </div>

          <div className='flex items-center gap-3 justify-end p-4'>
            <Link to="../" className='btn btn-ghost'>{t("commons.cancel")}</Link>
            <button className='btn btn-primary px-8'>{t("return.Update return")}</button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default UpdateReturn